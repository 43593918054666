import React from 'react'
import WalletsTransactionsManagementTable from './WalletsTransactionsManagementTable.container'

const WalletsTransactionsPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-wallets-transactions">
      <div>
        <WalletsTransactionsManagementTable />
      </div>
    </div>
  )
}

export default WalletsTransactionsPage
