import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { t } from '../i18n'

const alrtModule = withReactContent(Swal)

interface AlertParams {
  title?: string | null
  body?: string | null
  confirmButtonLabel?: string | null
}

const alrt = {
  success(params?: AlertParams) {
    const args = params || {}
    // @ts-ignore
    alrtModule.fire({
      type: 'success',
      title: args.title || t('alrt:common.success_title'),
      text: args.body || t('alrt:common.success_body'),
      confirmButtonText: args.confirmButtonLabel || t('alrt:common.confirm_button.label'),
    })
  },
  error(params?: AlertParams) {
    const args = params || {}
    // @ts-ignore
    alrtModule.fire({
      type: 'error',
      title: args.title || t('alrt:common.error_title'),
      text: args.body || t('alrt:common.error_body'),
      confirmButtonText: args.confirmButtonLabel || t('alrt:common.confirm_button.label'),
    })
  },
  info(params?: AlertParams) {
    const args = params || {}
    // @ts-ignore
    alrtModule.fire({
      type: 'info',
      title: args.title,
      text: args.body,
      confirmButtonText: args.confirmButtonLabel || t('alrt:common.confirm_button.label'),
    })
  },
}

export default alrt
