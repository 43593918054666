import React from 'react'
import { Image } from 'cloudinary-react'
import { projectId } from '../../services/firebase'

interface Props {
  name?: string | null
  spinner?: boolean
  fallbackLogo?: boolean
  fallbackAvatar?: boolean
  format?: string | null
  version?: string
  children?: React.ReactNode | React.ReactNodeArray
}

const CloudImage: React.FC<Props> = ({ name, spinner, fallbackLogo, fallbackAvatar, children, format, version, ...otherProps }) => {
  const formatSuffix = format || 'png'
  const publicId = name && !spinner ? `${projectId}/${name}.${formatSuffix}` : 'spinner.gif'

  const fallbackLogoImage = fallbackLogo ? `tradesoftware.${formatSuffix}` : undefined
  const fallbackAvatarImage = fallbackAvatar ? `avatar.${formatSuffix}` : undefined
  const fallbackImage = spinner ? undefined : fallbackLogoImage || fallbackAvatarImage

  const spinnerVersion = spinner ? '1620598408' : undefined

  return (
    <Image cloudName="inkryptus" publicId={publicId} defaultImage={fallbackImage} secure version={version || spinnerVersion} {...otherProps}>
      {children}
    </Image>
  )
}

export default CloudImage
