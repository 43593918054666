const DEFAULT_MESSAGE = 'Form errors'

export interface FieldError {
  name: string
  message: string
}

export interface FormErrors {
  [path: string]: FieldError[]
}

export class FormError extends Error {
  constructor(public formErrors: FormErrors, public message: string = DEFAULT_MESSAGE, public code?: string) {
    super(message)
  }
}

export default FormError
