import { Button, Input, notification } from 'antd'
import * as React from 'react'
import { updateProfileField } from '../../../services/api'

export interface MemberInfoFieldProps {
  editable?: boolean
  username?: string
  fieldName: 'birthDate' | 'gender' | 'phone' | 'country' | 'email' | 'name' | 'lastName'
  fieldValue: string
}

export const MemberInfoField: React.FC<MemberInfoFieldProps> = ({ editable, username, fieldName, fieldValue }) => {
  const [editMode, setEditMode] = React.useState(false)

  const [newFieldValue, setNewFieldValue] = React.useState('')
  const changeNewFieldValue = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!editMode) return
      setNewFieldValue(event.target.value)
    },
    [setNewFieldValue, editMode]
  )

  const enterEditMode = React.useCallback(() => {
    if (!editable) return
    setNewFieldValue(fieldValue)
    setEditMode(true)
  }, [setEditMode, setNewFieldValue, fieldValue, editable])
  const exitEditMode = React.useCallback(() => {
    setEditMode(false)
  }, [setEditMode])

  const [saving, setSaving] = React.useState(false)
  const saveNewFieldValue = React.useCallback(async () => {
    if (saving) return
    if (!newFieldValue) return
    if (!username) return
    setSaving(true)
    try {
      await updateProfileField(username, fieldName, newFieldValue)
      setEditMode(false)
    } catch (error) {
      notification.error({ message: `Failed to update user field "${fieldName}"! ${(error as any).message}` })
    }
    setSaving(false)
  }, [saving, setSaving, setEditMode, newFieldValue, fieldName, username])

  return (
    <Input.Group compact>
      <Input
        style={{
          width: `calc(100% - 32px - ${editMode ? 64 : 32}px)`,
        }}
        value={editMode ? newFieldValue : fieldValue}
        onChange={changeNewFieldValue}
      />
      {!editMode && !!editable && <Button icon="edit" onClick={enterEditMode} />}
      {editMode && <Button type="primary" icon="save" onClick={saveNewFieldValue} loading={saving} />}
      {editMode && <Button icon="close" onClick={exitEditMode} />}
    </Input.Group>
  )
}
