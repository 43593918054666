import { auth, AuthPersistence } from '../services/firebase'
import { FormError } from '../utils/form-errors'
import { t, resetLanguage } from '../i18n'
import HumanizedError from './humanized-error'

export async function signInWithEmailAndPassword(username: string, password: string): Promise<void> {
  const email: string = username + '@staff.secureusernames.inkryptus.com'
  try {
    await auth.setPersistence(AuthPersistence.SESSION)
    const { user } = await auth.signInWithEmailAndPassword(email, password)
    if (!user) throw new HumanizedError('Cannot found a user with this credentials', '')
  } catch (error) {
    handleAuthError(error)
  }
}

export async function signOut() {
  await auth.signOut()
  resetLanguage()
}

function handleAuthError(error: any) {
  if (error && error.code) {
    switch (error.code) {
      case 'auth/invalid-email':
        const invalidUsernameFieldError = { name: 'username', message: t('fields:username.invalid') }
        throw new FormError({ username: [invalidUsernameFieldError] }, t('errors:auth_invalid_username'))
      case 'auth/user-disabled':
        const disabledUsernameFieldError = { name: 'username', message: t('fields:username.disabled') }
        throw new FormError({ username: [disabledUsernameFieldError] }, t('errors:auth_user_disabled'))
      case 'auth/user-not-found':
        const inexistentUsernameFieldError = { name: 'username', message: t('fields:username.inexistent') }
        throw new FormError({ username: [inexistentUsernameFieldError] }, t('errors:auth_user_not_found'))
      case 'auth/wrong-password':
        const wrongPasswordFieldError = { name: 'password', message: t('fields:password.wrong') }
        throw new FormError({ password: [wrongPasswordFieldError] }, t('errors:auth_wrong_password'))
    }
  }
  throw error
}
