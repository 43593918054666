import * as React from 'react'
import { Moment } from './moment'
import { DefaultMomentProps } from './types'

export interface DateAndHourProps extends DefaultMomentProps {
  mode?: 'short' | 'long'
  format?: string
}
export const DateAndHour: React.FC<DateAndHourProps> = ({ time, mode, format: customFormat }) => {
  if (!time) return null
  const format = customFormat || mode === 'long' ? 'DD/MM/YYYY HH:mm' : 'DD/MM HH:mm'
  return <Moment format={format}>{time}</Moment>
}
