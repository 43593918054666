import React from 'react'
import { TableRowSelection } from 'antd/lib/table'
import { map, size } from 'lodash'
import { Decimal, decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { SwapWalletTransactionType } from '@allcoinwallet/inkryptus-db-schema'

import SwapWalletsTransactionsTable from './SwapWalletsTransactionsTable.component'
import useAllWalletsTransactions, { SwapWalletTransactionRow } from './all-wallets-transactions.hook'
import { useQueryParams } from '../../utils/url'
import { SwapWalletTransactionReportBroadcastedModal } from './SwapWalletTransactionReportBroadcastedModal'

const WalletsTransactionsManagementTable: React.FunctionComponent = () => {
  const [queryParams] = useQueryParams()
  const typeFilter: SwapWalletTransactionType | undefined = ['withdrawal', 'deposit', 'swap'].includes(queryParams.get('type') || '')
    ? (queryParams.get('type') as SwapWalletTransactionType)
    : undefined

  const [transactions] = useAllWalletsTransactions(typeFilter)

  const [selectedTransactions, setSelectedTransactions] = React.useState<SwapWalletTransactionRow[]>([])
  const rowSelection: TableRowSelection<SwapWalletTransactionRow> = React.useMemo(() => {
    return {
      selectedRowKeys: map(selectedTransactions, 'transactionId'),
      onChange: (keys, rows) => setSelectedTransactions(rows || []),
      // getCheckboxProps: (contract) => ({
      //   disabled: contract.status === 'PROCESSING' || contract.status === 'INACTIVE',
      // }),
    }
  }, [selectedTransactions, setSelectedTransactions])

  const [usersOfSelectedTransactions, selectedTransactionsAmount] = React.useMemo((): [number, decimal] => {
    const uniqueUsers: { [userId: string]: boolean } = {}
    let total = Decimal(0)
    for (const transaction of selectedTransactions) {
      uniqueUsers[transaction.userId] = true
      total = add(total, transaction.amount)
    }
    return [size(uniqueUsers), total]
  }, [selectedTransactions])

  const [withdrawingTransaction, setWithdrawingTransaction] = React.useState<SwapWalletTransactionRow>()
  const openReportBroadcastedModal = React.useCallback(
    (transaction: SwapWalletTransactionRow) => {
      if (transaction.confirmationTransactionHash) return
      setWithdrawingTransaction(transaction)
    },
    [setWithdrawingTransaction]
  )
  const hideReportBroadcastedModal = React.useCallback(() => {
    setWithdrawingTransaction(undefined)
  }, [setWithdrawingTransaction])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 15,
          height: 62,
        }}
      >
        {/* <Button
          style={{ marginRight: 16 }}
          disabled={!selectedTransactions.length || statusFilter !== 'PENDING_DEACTIVATION'}
          onClick={deactivateTransactions}
          loading={deactivatingTransactions}
        >
          Deactivate Transactions ({selectedTransactions.length})
        </Button> */}
        <span>
          Users of Selected Txs: {usersOfSelectedTransactions} | Total amount of Selected Txs: {selectedTransactionsAmount}
        </span>
      </div>
      <SwapWalletsTransactionsTable transactions={transactions} rowSelection={rowSelection} onRowClick={openReportBroadcastedModal} />
      <SwapWalletTransactionReportBroadcastedModal onClose={hideReportBroadcastedModal} transaction={withdrawingTransaction} />
    </div>
  )
}

export default WalletsTransactionsManagementTable
