import {
  MembersProfile,
  Profile,
  NetworkMembers,
  MembersStatuses,
  MembersWallets,
  MemberPublicProfile,
  MemberStatuses,
} from '@allcoinwallet/inkryptus-db-schema'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../services/firebase'
import { useDatabaseObject } from '../hooks/database'

type MembersHook = () => [MembersProfile | null, boolean, object | undefined]
export const useMembersProfile: MembersHook = () => {
  const { user } = useAuthState(auth)
  const membersProfileRef = user ? db.child('membersProfile') : undefined
  const { value: membersProfile, loading, error } = useDatabaseObject(membersProfileRef)
  if (error) console.log('useMembersProfile', error)
  return [membersProfile || null, loading, error]
}

type MemberHook = (username?: string) => [Profile | null, boolean, object | undefined]
export const useMemberProfile: MemberHook = (username) => {
  const { user } = useAuthState(auth)
  const memberProfileRef = user && username ? db.child('membersProfile').child(username) : undefined
  const { value: memberProfile, loading, error } = useDatabaseObject(memberProfileRef)
  if (error) console.log('useMemberProfile', error)
  return [memberProfile || null, loading, error]
}

export function usePublicProfile(username?: string): [MemberPublicProfile | null, boolean, object | undefined] {
  const profileRef = username ? db.child('membersPublicProfile').child(username) : undefined
  const publicProfile = useDatabaseObject(profileRef)
  return [publicProfile.value || null, publicProfile.loading, publicProfile.error]
}

export function useMemberStatuses(uid?: string): [MemberStatuses | null, boolean, object | undefined] {
  const { user } = useAuthState(auth)
  const memberStatusesRef = user && uid ? db.child('membersStatuses').child(uid) : undefined
  const { value: memberStatuses, loading, error } = useDatabaseObject(memberStatusesRef)
  if (error) console.log('useMemberStatuses', error)
  return [memberStatuses || null, loading, error]
}

type MembersStatusesHook = () => [MembersStatuses | null, boolean, object | undefined]
export const useMembersStatuses: MembersStatusesHook = () => {
  const { user } = useAuthState(auth)
  const membersStatusesRef = user ? db.child('membersStatuses') : undefined
  const { value: membersStatuses, loading, error } = useDatabaseObject(membersStatusesRef)
  if (error) console.log('useMembersStatuses', error)
  return [membersStatuses || null, loading, error]
}

type NetworkMembersHook = () => [NetworkMembers | null, boolean, object | undefined]
export const useNetworkMembers: NetworkMembersHook = () => {
  const { user } = useAuthState(auth)
  const networkMembersRef = user ? db.child('networkMembers') : undefined
  const { value: networkMembers, loading, error } = useDatabaseObject(networkMembersRef)
  if (error) console.log('useNetworkMembers', error)
  return [networkMembers || null, loading, error]
}

type MembersWalletsHook = (symbol?: string) => [MembersWallets | undefined, boolean, object | undefined]
export const useMembersWallets: MembersWalletsHook = (symbol) => {
  const membersWalletsQuery = symbol ? db.child('membersWallets').orderByChild('symbol').equalTo(symbol) : null
  const { value, loading, error } = useDatabaseObject(membersWalletsQuery)
  return [value, loading, error]
}
