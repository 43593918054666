import React from 'react'
import { TableRowSelection } from 'antd/lib/table'
import { Button, message, notification } from 'antd'
import { map, size } from 'lodash'
import { Decimal, decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'

import ProductsContractsTable from './ProductsContractsTable.component'
import useAllProductsContracts, { ProductContractRow } from './all-products-contracts.hook'
import { useQueryParams } from '../../utils/url'
import { ProductsContractsProfitReport } from './ProductsContractsProfitReportModal'
import { activateProductsContracts, deactivateProductsContracts, getWalletsBscBalance, walletsBscForwardFunds } from '../../services/api'

const ProductsContractsManagementTable: React.FunctionComponent = () => {
  const [queryParams] = useQueryParams()
  const statusFilter = queryParams.get('status') || undefined
  const symbol = queryParams.get('symbol') || undefined
  const productId = queryParams.get('productId') || 'cake-smart-farm'

  const [contracts] = useAllProductsContracts({ status: statusFilter, symbol })

  const [selectedContracts, setSelectedContracts] = React.useState<ProductContractRow[]>([])
  const rowSelection: TableRowSelection<ProductContractRow> = React.useMemo(() => {
    return {
      selectedRowKeys: map(selectedContracts, 'contractId'),
      onChange: (_, rows) => setSelectedContracts(rows || []),
      getCheckboxProps: (contract) => ({
        disabled: contract.status === 'PROCESSING' || contract.status === 'INACTIVE',
      }),
    }
  }, [selectedContracts, setSelectedContracts])

  const [usersOfSelectedContracts, selectedContractsAmount] = React.useMemo((): [number, decimal] => {
    const count = size(selectedContracts)
    let total = Decimal(0)
    for (const contract of selectedContracts) {
      total = add(total, contract.netAmount)
    }
    return [count, total]
  }, [selectedContracts])

  const [reportFitModalVisible, setReportFitModalVisible] = React.useState(false)
  const openReportFitModal = React.useCallback(() => {
    setReportFitModalVisible(true)
  }, [setReportFitModalVisible])
  const hideReportFitModal = React.useCallback(() => {
    setReportFitModalVisible(false)
    setSelectedContracts([])
  }, [setReportFitModalVisible])

  const [activating, setActivating] = React.useState(false)
  const activate = React.useCallback(async () => {
    if (activating) return
    setActivating(true)
    try {
      await activateProductsContracts(productId, map(selectedContracts, 'contractId'))
      notification.success({ message: 'Products Contracts Activated Successfully!', description: `${selectedContracts.length} contracts` })
      setSelectedContracts([])
    } catch (error) {
      console.error('Error activating products contracts', error, selectedContracts)
      notification.error({ message: 'Error activating products contracts!', description: (error as any).message || '' })
    }
    setActivating(false)
  }, [activating, setActivating, selectedContracts, setSelectedContracts, productId])

  const [managingBalances, setManagingBalances] = React.useState(false)
  const [balances, setBalances] = React.useState<{ [userId: string]: { address: string; balance: decimal } }>({})
  const checkAndForwardBalances = React.useCallback(async () => {
    if (managingBalances) return
    if (!symbol) return
    setManagingBalances(true)
    const count = size(balances)
    try {
      if (count) {
        await walletsBscForwardFunds(symbol)
        setBalances({})
      } else {
        const nextBalances = await getWalletsBscBalance(symbol)
        console.log('next balances', nextBalances)
        setBalances(nextBalances || {})
      }
    } catch (error) {
      message.error('failed to manage balances')
    }
    setManagingBalances(false)
  }, [managingBalances, setManagingBalances, balances, setBalances, symbol])
  const [walletsWithBalance, balanceAmount] = React.useMemo((): [number, decimal] => {
    const count = size(balances)
    let total = Decimal(0)
    for (const userId in balances) {
      const { balance } = balances[userId]
      total = add(total, balance)
    }
    return [count, total]
  }, [balances])

  const [deactivatingContracts, setDeactivatingContracts] = React.useState(false)
  const deactivateContracts = React.useCallback(async () => {
    if (deactivatingContracts) return
    setDeactivatingContracts(true)
    try {
      await deactivateProductsContracts(productId, map(selectedContracts, 'contractId'))
      notification.success({ message: 'Products Contracts Deactivated Successfully!', description: `${selectedContracts.length} contracts` })
      setSelectedContracts([])
    } catch (error) {
      message.error('failed to deactivate contracts')
    }
    setDeactivatingContracts(false)
  }, [deactivatingContracts, setDeactivatingContracts, selectedContracts, setSelectedContracts, productId])

  React.useEffect(() => {
    setBalances({})
    setSelectedContracts([])
  }, [symbol, statusFilter, setBalances, setSelectedContracts])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 15,
          height: 62,
        }}
      >
        <Button
          style={{ marginRight: 16 }}
          disabled={!selectedContracts.length || statusFilter !== 'PENDING_ACTIVATION'}
          onClick={activate}
          loading={activating}
        >
          {`Activate ${selectedContractsAmount} ${symbol} of ${usersOfSelectedContracts} Users`}
        </Button>
        <Button style={{ marginRight: 16 }} onClick={checkAndForwardBalances} loading={managingBalances}>
          {walletsWithBalance ? `Forward ${balanceAmount} ${symbol} of ${walletsWithBalance} Users` : `Check ${symbol} Available`}
        </Button>
        <Button style={{ marginRight: 16 }} disabled={!selectedContracts.length || statusFilter !== 'ACTIVE'} onClick={openReportFitModal}>
          Report Profit Cycle ({selectedContracts.length})
        </Button>
        <Button
          style={{ marginRight: 16 }}
          disabled={!selectedContracts.length || statusFilter !== 'PENDING_DEACTIVATION'}
          onClick={deactivateContracts}
          loading={deactivatingContracts}
        >
          Deactivate Contracts ({selectedContracts.length})
        </Button>
      </div>
      <ProductsContractsTable contracts={contracts} rowSelection={rowSelection} />
      <ProductsContractsProfitReport show={reportFitModalVisible} onClose={hideReportFitModal} contracts={selectedContracts} />
    </div>
  )
}

export default ProductsContractsManagementTable
