import React from 'react'
import { Row, Col, Card, Icon, Statistic, Button } from 'antd'
import { cmp, decimal, Decimal, sig, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { size } from 'lodash'
import { formatDecimal } from '../../utils/format'
import { useCompanyWallet, useMembersStakeWalletsSymbolSummary } from '../../modules/wallets'

export interface MembersBalanceWidgetsProps {
  type: 'stake' | 'swap'
  symbol: string
  onVaultWithdrawalClick?: (type: 'stake' | 'swap', symbol: string) => void
  onLockTransactionsListClick?: (type: 'stake' | 'swap', symbol: string, lockTransactions: { [transactionId: string]: decimal }) => void
  onAvailableBalanceOwnersListClick?: (type: 'stake' | 'swap', symbol: string, availableOwners: { [userId: string]: decimal }) => void
}

const FEATURE_FLAG_AVAILABLE_BUTTON = true

const MembersBalanceWidgets: React.FC<MembersBalanceWidgetsProps> = ({
  type,
  symbol,
  onVaultWithdrawalClick,
  onLockTransactionsListClick,
  onAvailableBalanceOwnersListClick,
}) => {
  const [vaultWallet, vaultWalletLoading] = useCompanyWallet(type, 'vault', symbol)
  const [stakeWallets, stakeWalletsLoading] = useMembersStakeWalletsSymbolSummary(symbol)

  const availableVaultBalance = vaultWallet?.balance.available

  const availableBalance = stakeWallets?.available
  const lockedBalance = stakeWallets?.locked
  const totalBalance = stakeWallets?.total

  const availableOwnersUnpure = stakeWallets?.availableOwners
  const availableOwners = React.useMemo(() => {
    if (!availableOwnersUnpure) return
    const collection: { [userId: string]: decimal } = {}
    for (const userId in availableOwnersUnpure) {
      const amount = trunc(availableOwnersUnpure[userId], 8)
      if (sig(amount) > 0) collection[userId] = amount
    }
    return collection
  }, [availableOwnersUnpure])
  const availableOwnersCount = React.useMemo(() => size(availableOwners), [availableOwners])

  const lockTransactions = stakeWallets?.lockTransactions

  const propagateVaultWithdrawalClick = React.useCallback(() => {
    if (!onVaultWithdrawalClick) return
    onVaultWithdrawalClick(type, symbol)
  }, [type, symbol, onVaultWithdrawalClick])
  const propagateLockTransactionsListClick = React.useCallback(() => {
    if (!onLockTransactionsListClick) return
    onLockTransactionsListClick(type, symbol, lockTransactions || {})
  }, [type, symbol, onLockTransactionsListClick, lockTransactions])
  const propagateAvailableBalanceOwnersListClick = React.useCallback(() => {
    if (!onAvailableBalanceOwnersListClick) return
    onAvailableBalanceOwnersListClick(type, symbol, availableOwners || {})
  }, [type, symbol, onAvailableBalanceOwnersListClick, availableOwners])

  const loading = vaultWalletLoading || stakeWalletsLoading

  return (
    <>
      {availableVaultBalance && sig(trunc(availableVaultBalance, 8)) > 0 && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Card loading={loading}>
              <Statistic
                title={`${symbol} Vault`}
                value={formatDecimal(availableVaultBalance, 8)}
                valueStyle={
                  type === 'stake' || cmp(availableVaultBalance, availableBalance || Decimal(0)) > 0
                    ? { color: '#1890ff' }
                    : { color: '#cf1322' }
                }
                prefix={<Icon type="wallet" />}
                suffix={symbol}
                decimalSeparator="."
                groupSeparator=","
              />
              {type === 'swap' && (
                <Button
                  type="link"
                  icon="export"
                  style={{ position: 'absolute', right: 24, top: 24, color: 'rgba(0,0,0,.45)' }}
                  onClick={propagateVaultWithdrawalClick}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Card loading={loading}>
            <Statistic
              title={`${symbol} Available`}
              value={availableBalance ? formatDecimal(availableBalance, 8) : '-'}
              valueStyle={availableBalance && sig(trunc(availableBalance, 8)) > 0 ? { color: '#3f8600' } : { opacity: 0.5 }}
              prefix={<Icon type="unlock" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
            {FEATURE_FLAG_AVAILABLE_BUTTON && availableOwnersCount > 0 && (
              <Button
                type="link"
                icon={availableOwnersCount === 1 ? 'user' : 'team'}
                style={{ position: 'absolute', right: 24, top: 24, color: 'rgba(0,0,0,.45)' }}
                onClick={propagateAvailableBalanceOwnersListClick}
              />
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card loading={loading}>
            <Statistic
              title={`${symbol} Locked`}
              value={lockedBalance ? formatDecimal(lockedBalance, 8) : '-'}
              valueStyle={lockedBalance && sig(trunc(lockedBalance, 8)) > 0 ? { color: '#cf1322' } : { opacity: 0.5 }}
              prefix={<Icon type="lock" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
            {!!lockTransactions && (
              <Button
                type="link"
                icon="bars"
                style={{ position: 'absolute', right: 24, top: 24, color: 'rgba(0,0,0,.45)' }}
                onClick={propagateLockTransactionsListClick}
              />
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card loading={loading}>
            <Statistic
              title={`${symbol} Total`}
              value={totalBalance ? formatDecimal(totalBalance, 8) : '-'}
              valueStyle={totalBalance && sig(trunc(totalBalance, 8)) ? { color: '#1890ff' } : { opacity: 0.5 }}
              prefix={<Icon type="calculator" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default MembersBalanceWidgets
