import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { chain, split } from 'lodash'

export function getQueryParams(searchString: string): { [param: string]: string | undefined } {
  return chain(searchString)
    .replace('?', '')
    .split('&')
    .map((s) => split(s, '=', 2))
    .fromPairs()
    .value()
}

export type SetParamsState = (nextParams: URLSearchParams) => URLSearchParams | undefined | void | any
export function useQueryParams(): [URLSearchParams, (setParamsState: SetParamsState) => void] {
  const history = useHistory()
  const search = history.location.search
  const pathname = history.location.pathname
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search])
  const setQueryParams = React.useCallback(
    (setParamsState: SetParamsState) => {
      const paramsToMutate = new URLSearchParams(search)
      const changedParams = setParamsState(paramsToMutate)
      const nextParams = changedParams instanceof URLSearchParams ? changedParams : paramsToMutate
      history.push({ pathname, search: nextParams.toString() })
    },
    [search, pathname, history]
  )
  return [queryParams, setQueryParams]
}
