import config from '../config'

const isTestnet = config.firebase.projectId !== 'inkryptus'

export function getWalletAddressExplorerUrl(walletAddress: string): string {
  return `https://www.blockchain.com/btc${isTestnet ? 'test' : ''}/address/${walletAddress}`
}

export function getBscWalletAddressExplorerUrl(walletAddress: string): string {
  return `https://${isTestnet ? 'testnet.' : ''}bscscan.com/address/${walletAddress}`
}

export function getBscTransactionExplorerUrl(transactionHash: string): string {
  return `https://${isTestnet ? 'testnet.' : ''}bscscan.com/tx/${transactionHash}`
}

export default isTestnet
