import React from 'react'
import { Table } from 'antd'
import { ColumnProps, TableRowSelection } from 'antd/lib/table'
import { decimal, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { WalletTransactionRow } from './all-wallets-transactions.hook'
import { BscLinkIcon } from '../../components/bsc-link-icon'
import { DateAndHour, CounterFromNow } from '../../components/moment'

const MAX_CAKE_DECIMALS = 18

const columns: ColumnProps<WalletTransactionRow>[] = [
  {
    title: 'ID',
    dataIndex: 'transactionId',
    key: 'transactionId',
    render: (transactionId: string, transaction) =>
      transaction.type === 'deposit' || transaction.confirmationTransactionHash ? (
        <p className="text-overflow-ellipsis" style={{ maxWidth: 120, display: 'inline-block' }}>
          <BscLinkIcon txId={transaction.confirmationTransactionHash || transactionId} /> {transactionId}
        </p>
      ) : (
        <p className="text-overflow-ellipsis" style={{ maxWidth: 120, display: 'inline-block' }}>
          {transactionId}
        </p>
      ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Member',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'profile.email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, transaction) =>
      transaction.confirmationTime
        ? 'CONFIRMED'
        : transaction.confirmationTransactionHash
        ? 'BROADCASTED'
        : transaction.authorizationTime
        ? 'AUTHORIZED'
        : transaction.failureReason
        ? 'FAILED'
        : 'PENDING',
  },
  {
    title: 'Request Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    defaultSortOrder: 'ascend',
    sorter: (recordA, recordB) => recordA.timestamp - recordB.timestamp,
    render: (timestamp: number) => (
      <p>
        <DateAndHour time={timestamp} /> (<CounterFromNow time={timestamp} />)
      </p>
    ),
  },
  {
    title: 'Tx Hash',
    dataIndex: 'confirmationTransactionHash',
    key: 'confirmationTransactionHash',
    render: (confirmationTransactionHash?: string) =>
      confirmationTransactionHash ? (
        <p className="text-overflow-ellipsis" style={{ maxWidth: 120, display: 'inline-block' }}>
          <BscLinkIcon txId={confirmationTransactionHash} /> {confirmationTransactionHash}
        </p>
      ) : (
        ''
      ),
  },
  {
    title: 'Gross Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (recordA, recordB) => Number(recordA.amount) - Number(recordB.amount),
    render: (amount: decimal) => trunc(amount, MAX_CAKE_DECIMALS),
  },
  {
    title: 'Fee Amount',
    dataIndex: 'feeAmount',
    key: 'feeAmount',
    sorter: (recordA, recordB) => Number(recordA.feeAmount) - Number(recordB.feeAmount),
    render: (feeAmount: decimal) => trunc(feeAmount, MAX_CAKE_DECIMALS),
  },
  {
    title: 'Net Amount',
    dataIndex: 'netAmount',
    key: 'netAmount',
    sorter: (recordA, recordB) => Number(recordA.netAmount) - Number(recordB.netAmount),
    render: (netAmount: decimal) => trunc(netAmount, MAX_CAKE_DECIMALS),
  },
]

interface Props {
  transactions: Array<WalletTransactionRow>
  rowSelection?: TableRowSelection<WalletTransactionRow>
  onRowClick?: (transaction: WalletTransactionRow) => any
}
const WalletsTransactionsTable: React.FunctionComponent<Props> = (props) => {
  return (
    <Table
      dataSource={props.transactions}
      size="small"
      columns={columns}
      pagination={false}
      rowKey="contractId"
      rowSelection={props.rowSelection}
      onRowClick={props.onRowClick}
    />
  )
}

export default WalletsTransactionsTable
