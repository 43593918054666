import React from 'react'
import { Table } from 'antd'
import { ProductLicense } from '@allcoinwallet/inkryptus-db-schema'

const columns = [
  {
    title: 'License Key',
    dataIndex: 'licenseKey',
    key: 'licenseKey',
  },
  {
    title: 'Product',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Member',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Activation',
    dataIndex: 'activationTime',
    key: 'activationTime',
  },
  {
    title: 'Expiration',
    dataIndex: 'expirationTime',
    key: 'expirationTime',
  },
]

interface Props {
  licenses: Array<ProductLicense & { licenseKey: string }>
}
const LicensesTable: React.FunctionComponent<Props> = props => {
  return <Table dataSource={props.licenses} columns={columns} pagination={false} />
}

export default LicensesTable
