import React from 'react'
import { map } from 'lodash'
import { ProductLicense } from '@allcoinwallet/inkryptus-db-schema'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../services/firebase'
import { useDatabaseObject } from '../../hooks/database'

type UseAllLicensesHook = () => [Array<ProductLicense & { licenseKey: string }>, boolean, object | undefined]
const useAllLicenses: UseAllLicensesHook = () => {
  const { user } = useAuthState(auth)
  const licensesRef = user ? db.child('productLicenses') : undefined
  const { value: licensesCollection, loading, error } = useDatabaseObject(licensesRef)
  const licenses: Array<ProductLicense & { licenseKey: string }> = React.useMemo(() => {
    if (!licensesCollection) return []
    return map(licensesCollection, (license, licenseKey) => ({ ...license, licenseKey }))
  }, [licensesCollection])
  return [licenses, loading, error]
}

export default useAllLicenses
