import React from 'react'
import { map, filter } from 'lodash'
import { Profile, ProductContract } from '@allcoinwallet/inkryptus-db-schema'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../services/firebase'
import { useDatabaseObject } from '../../hooks/database'
import { useMembersProfile, useMembersStatuses } from '../../modules/members'

export type ProductContractRow = ProductContract & { contractId: string; username?: string; profile?: Profile }

type UseAllProductsContractssHook = (filters?: { status?: string; symbol?: string }) => [Array<ProductContractRow>, boolean, object | undefined]
const useAllProductsContracts: UseAllProductsContractssHook = (filters) => {
  const symbolFilter = filters?.symbol
  const statusFilter = filters?.status

  const [membersStatuses, membersStatusesLoading] = useMembersStatuses()
  const [membersProfile, membersProfileLoading] = useMembersProfile()

  const { user } = useAuthState(auth)
  // let contractsRef: Query<ProductsContracts> | undefined = user ? db.child('productsContracts') : undefined
  // if (statusFilter && !symbol && contractsRef) contractsRef = contractsRef.orderByChild('status').equalTo(statusFilter)
  // if (!statusFilter && symbol && contractsRef) contractsRef = contractsRef.orderByChild('symbol').equalTo(symbol)
  const { value: contractsCollection, loading: contractsLoading, error } = useDatabaseObject(user ? db.child('productsContracts') : undefined)

  const contracts: Array<ProductContractRow> = React.useMemo(() => {
    if (!contractsCollection) return []
    let contractsFilter = map(contractsCollection, (contract, contractId) => {
      const [, profile] = getMemberProfile(contract.userId)
      return {
        ...contract,
        contractId,
        profile,
      }
    })
    if (statusFilter) contractsFilter = filter(contractsFilter, (contract) => contract.status === statusFilter)
    if (symbolFilter) contractsFilter = filter(contractsFilter, (contract) => contract.symbol === symbolFilter)
    return contractsFilter
    function getMemberProfile(userId: string): [string, Profile] | [undefined, undefined] {
      if (!membersStatuses || !membersStatuses[userId]) return [undefined, undefined]
      const username = membersStatuses[userId].username
      if (!membersProfile || !membersProfile[username]) return [undefined, undefined]
      return [username, membersProfile[username]]
    }
  }, [contractsCollection, membersStatuses, membersProfile, symbolFilter, statusFilter])

  const loading = contractsLoading || membersStatusesLoading || membersProfileLoading

  return [contracts, loading, error]
}

export default useAllProductsContracts
