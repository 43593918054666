import * as React from 'react'
import { get } from 'lodash'
import { useState, useRef, useEffect } from 'react'
import { Input, Button, Form, Icon } from 'antd'

import { useFormErrors } from '../../hooks/form-errors'

const withTargetValue = (setFn: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => setFn(e.target.value)
const withKeyEnterPress = (callback: () => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') callback()
}

interface Props {
  onSubmit: (data: { username: string; password: string }) => Promise<void>
}
const SigninForm: React.FunctionComponent<Props> = (props) => {
  const [username, setUsername] = useState<string>('')
  const usernameEl = useRef<Input>(null)
  const [password, setPassword] = useState<string>('')
  const passwordEl = useRef<Input>(null)

  const [formErrors, setFormErrors, solveFormError] = useFormErrors()
  const [loading, setLoading] = useState<boolean>(false)

  const formRef = React.useRef<any>(null)

  const submitFormWithKeyEnterPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return
    if (!formRef.current) return
    formRef.current.submit()
  }, [])

  const propsOnSubmit = props.onSubmit
  const onSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    async (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (loading) return
      setLoading(true)
      try {
        await propsOnSubmit({
          username,
          password,
        })
      } catch (error) {
        if ((error as any).formErrors) {
          setFormErrors((error as any).formErrors)
        }
      }
      setLoading(false)
    },
    [loading, setLoading, setFormErrors, propsOnSubmit, username, password]
  )

  const onBlur = (fieldName: string) => () => {
    solveFormError(fieldName)
  }

  const focusOnUsername = () => {
    if (!usernameEl.current) return
    usernameEl.current.focus()
  }
  useEffect(focusOnUsername, [])
  const focusOnPassword = () => {
    if (!passwordEl.current) return
    passwordEl.current.focus()
  }

  return (
    <div className="box-login">
      <div className="logo">
        <img src="/logo-inkryptus-dark.svg" />
      </div>
      <Form ref={formRef} onSubmit={onSubmit} className="login-form" target="">
        <Form.Item
          validateStatus={formErrors['username'] ? 'error' : undefined}
          help={formErrors['username'] ? get(formErrors['username'], '0.message') : undefined}
        >
          <Input
            ref={usernameEl}
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            value={username}
            onChange={withTargetValue(setUsername)}
            onBlur={onBlur('username')}
            onKeyPress={withKeyEnterPress(focusOnPassword)}
          />
        </Form.Item>
        <Form.Item
          validateStatus={formErrors['password'] ? 'error' : undefined}
          help={formErrors['password'] ? get(formErrors['password'], '0.message') : undefined}
        >
          <Input
            ref={passwordEl}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            value={password}
            onChange={withTargetValue(setPassword)}
            onBlur={onBlur('password')}
            onKeyPress={submitFormWithKeyEnterPress}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button-inkryptus" disabled={loading} loading={loading}>
            Open the dashboard
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SigninForm
