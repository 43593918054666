import React from 'react'
import { get } from 'lodash'
import { ColumnProps, PaginationConfig, SorterResult, TableCurrentDataSource } from 'antd/lib/table'
import * as csvGenerator from 'csv-generator-client'
import { toCleanedASCII } from '../utils/string'

type CellData = string | number | null
export interface TableColumnProps<T> extends ColumnProps<T> {
  onExport?: (item: T) => CellData
}

type TableOnChangeCallback<T> = (
  pagination: PaginationConfig,
  filters: Partial<Record<keyof T, string[]>>,
  sorter: SorterResult<T>,
  extra: TableCurrentDataSource<T>
) => void
export interface ExportableTableHook<T> {
  onChangeHandler: TableOnChangeCallback<T>
  downloadCsv: () => void
}
export function useExportableTable<T>(columns: TableColumnProps<T>[], baseDataSource?: T[]): ExportableTableHook<T> {
  const [dataSource, setDataSource] = React.useState<T[] | undefined>(undefined)

  const onChangeHandler: TableOnChangeCallback<T> = React.useCallback((pagination, filters, sorter, { currentDataSource }) => {
    setDataSource(currentDataSource)
  }, [])

  const downloadCsv = React.useCallback(() => {
    const source = dataSource || baseDataSource
    if (!source) return

    const reportRows: CellData[][] = []

    const headerRow: CellData[] = []
    for (const column of columns) headerRow.push(String(column.title))
    reportRows.push(headerRow)

    for (const item of source) {
      const itemRow: CellData[] = []
      for (const column of columns) {
        let cell: CellData = null
        if (column.dataIndex) cell = get(item, column.dataIndex, null)
        if (column.onExport) cell = column.onExport(item)
        if (typeof cell === 'string') cell = toCleanedASCII(cell)
        itemRow.push(cell)
      }
      reportRows.push(itemRow)
    }

    const settings = { separator: ',', addQuotes: true, autoDetectColumns: false }
    const fileName = 'export.csv'
    csvGenerator.download({ settings, fileName, dataArray: reportRows })
  }, [dataSource, baseDataSource, columns])

  return { onChangeHandler, downloadCsv }
}
