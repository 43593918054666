import * as React from 'react'
import { Icon } from 'antd'
import { useQueryParams } from '../../utils/url'

export interface MemberProfileButtonProps {
  username: string
}

export const MemberProfileButton: React.FC<MemberProfileButtonProps> = ({ username }) => {
  const [, setQueryParams] = useQueryParams()
  const setUidOnQueryParams = React.useCallback(() => {
    setQueryParams((s) => {
      s.set('member_overview_username', username)
    })
  }, [setQueryParams, username])
  return <Icon type="user" onClick={setUidOnQueryParams} />
}
