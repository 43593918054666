import axios, { AxiosInstance } from 'axios'
import { get } from 'lodash'
import config from '../config'
import { getLanguage } from '../i18n'
import { auth } from '../services/firebase'
import FormError from '../utils/form-errors'
import HumanizedError from '../utils/humanized-error'
import alrt from '../utils/alrt'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'

/* API ENDPOINTS */

export function getSymbolByProductId(productId: string): string {
  if (productId === 'banana-smart-farm') return 'BANANA'
  if (productId === 'biswap-smart-farm') return 'BSW'
  if (productId === 'ole-busd-pool') return 'OLEBUSD'
  if (productId === 'grvs-busd-pool') return 'GRVSBUSD'
  if (productId === 'vtg-bnb-pool') return 'VTGBNB'
  if (productId === 'wmx-busd-pool') return 'WMXBUSD'
  if (productId === 'krs-busd-pool') return 'KRSBUSD'
  if (productId === 'squad-cake-pool') return 'SQUADCAKE'
  if (productId === 'inky-super-pool-6month') return 'INKY'
  if (productId === 'inky-super-pool-12month') return 'INKY'
  return 'CAKE'
}

export function getProductIdBySymbol(symbol: string): string {
  if (symbol === 'BANANA') return 'banana-smart-farm'
  if (symbol === 'BSW') return 'biswap-smart-farm'
  if (symbol === 'OLEBUSD') return 'ole-busd-pool'
  if (symbol === 'GRVSBUSD') return 'grvs-busd-pool'
  if (symbol === 'VTGBNB') return 'vtg-bnb-pool'
  if (symbol === 'WMXBUSD') return 'wmx-busd-pool'
  if (symbol === 'KRSBUSD') return 'krs-busd-pool'
  if (symbol === 'SQUADCAKE') return 'squad-cake-pool'
  return 'cake-smart-farm'
}

export async function reportTokenRecognition(jwt: string): Promise<void> {
  await req('post', '/auth/token_recognition', { jwt })
}

export async function requestResetPassword(usernameOrEmail: string): Promise<void> {
  await req('post', '/auth/reset_password', { usernameOrEmail })
}

export async function resetPassword(jwt: string, password: string): Promise<void> {
  await req('post', '/auth/password', { jwt, password })
}

export async function refreshMemberLang(lang: string): Promise<void> {
  await req('post', '/member/lang', { lang })
}

export async function activateProductsContracts(productId: string, contractsIds: string[]): Promise<void> {
  await req('post', `/products/${productId}/contracts_activation`, { contractsIds })
}

export async function deactivateProductsContracts(productId: string, contractsIds: string[]): Promise<void> {
  await req('post', `/products/${productId}/contracts_deactivation`, { contractsIds })
}

export async function reportProductsContractsProfit(productId: string, contractsIds: string[], profit: decimal): Promise<void> {
  await req('post', `/products/${productId}/contracts_profit`, { contractsIds, profit })
}

export async function getWalletsBscBalance(symbol: string): Promise<{ [userId: string]: { address: string; balance: decimal } }> {
  return await req('get', `/wallets/${symbol}/bsc_balances`, {})
}

export async function walletsBscForwardFunds(symbol: string): Promise<void> {
  await req('post', `/wallets/${symbol}/bsc_forward_funds_batch`, {})
}

export async function walletsReportBroadcastedWithdrawal(symbol: string, transactionId: string, transactionHash: string): Promise<void> {
  await req('patch', `/wallets/${symbol}/withdrawals/${transactionId}/`, { transactionHash })
}

export async function walletsReportConfirmedHarvest(symbol: string, transactionId: string): Promise<void> {
  await req('patch', `/wallets/${symbol}/harvest/${transactionId}/`, {})
}

export async function walletsAbortTransaction(symbol: string, transactionId: string): Promise<void> {
  await req('delete', `/wallets/${symbol}/transactions/${transactionId}/`, {})
}

export async function swapWalletsReportBroadcastedWithdrawal(symbol: string, transactionId: string, transactionHash: string): Promise<void> {
  await req('patch', `/swap_wallets/${symbol}/withdrawals/${transactionId}/`, { transactionHash })
}

export async function swapWalletsAbortTransaction(symbol: string, transactionId: string): Promise<void> {
  await req('delete', `/swap_wallets/${symbol}/transactions/${transactionId}/`, {})
}

export async function swapWalletsWithdrawConversionVault(symbol: string, grossAmount: decimal): Promise<{ transactionId: string }> {
  return await req('post', `/swap_wallets/${symbol}/conversion_vault_withdrawals/`, { grossAmount })
}

export async function walletsReportHarvestConfirmation(symbol: string, transactionId: string): Promise<void> {
  await req('patch', `/wallets/${symbol}/harvest/${transactionId}/`, {})
}

export async function sendNotificationMessage(usernames: string[], title: string, messageBody: string): Promise<void> {
  await req('post', '/notifications/', { usernames, title, messageBody })
}

export async function blockMember(uid: string): Promise<void> {
  await req('post', '/auth/block_member/', { uid })
}

export async function unblockMember(uid: string): Promise<void> {
  await req('post', '/auth/unblock_member/', { uid })
}

export async function updateProfileField(
  username: string,
  fieldName: 'birthDate' | 'gender' | 'phone' | 'country' | 'email' | 'name' | 'lastName',
  fieldValue: string
): Promise<void> {
  await req('patch', `/profiles/${username}/`, { fieldName, fieldValue })
}

async function getApiInstance() {
  const headers: { [key: string]: string } = {}
  headers['User-Language'] = getLanguage()
  if (auth.currentUser) headers['User-Id-Token'] = await auth.currentUser.getIdToken()
  const instance: AxiosInstance = axios.create({ baseURL: config.apiUrl, headers })
  return instance
}

interface RequestOptions {
  alert?: boolean
}
const defaultRequestOptions: RequestOptions = {
  alert: false,
}
async function req<R = any>(
  method: 'post' | 'get' | 'patch' | 'delete',
  path: string,
  payload: any,
  options: RequestOptions = defaultRequestOptions
): Promise<R> {
  const api = await getApiInstance()
  const tz = Date.now()
  console.log(`${tz}: Request started`, { req: { method, path, payload } })
  try {
    const response = await api.request({ method, url: path, data: payload })
    const { data, status } = response
    console.log(`${tz}: Request finishes`, { req: { method, path, payload }, res: { status, data } })
    if (options.alert) {
      const alertMessage = get(data, 'humanizedMessage', null)
      alrt.success({ body: alertMessage })
    }
    return data
  } catch (e) {
    console.log(`${tz}: Request fails`, { req: { method, path, payload }, error: e })
    if ((e as any).request) {
      const { data } = (e as any).response
      if (data && data.formErrors) {
        throw new FormError(data.formErrors, data.message)
      }
      if (data && data.humanizedMessage) {
        if (options.alert) {
          alrt.error({ body: data.humanizedMessage })
        }
        throw new HumanizedError(data.humanizedMessage, data.message)
      }
      if (options.alert) alrt.error()
    }
    throw e
  }
}
