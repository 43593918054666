import React from 'react'
import { Table, Icon } from 'antd'
import { decimal, sig } from '@allcoinwallet/invest-bitcoin-decimal'
import { ProductLicenseStatus } from '@allcoinwallet/inkryptus-db-schema'
import { formatCurrency } from '../../utils/format'
import { useExportableTable, TableColumnProps } from '../../hooks/table'

interface TableItem {
  licenseKey: string
  licenseKeyAlias: string
  productAlias: string
  username: string
  email: string
  status: ProductLicenseStatus
  activationTime: number | null
  renewalTime: number | null
  expirationTime: number | null
  activationTimeFmt: string
  renewalTimeFmt: string
  expirationTimeFmt: string
  balanceAmount: decimal | null | undefined
  profitCurrencyCode: string | null | undefined
  profitAmount: decimal | null | undefined
}

const columns: TableColumnProps<TableItem>[] = [
  {
    title: 'License',
    dataIndex: 'licenseKeyAlias',
    key: 'licenseKeyAlias',
    width: 70,
    onExport: (item) => item.licenseKey,
  },
  {
    title: 'Product',
    dataIndex: 'productAlias',
    key: 'productAlias',
  },
  {
    title: 'Member',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filterMultiple: true,
    filters: [
      { text: 'Pending', value: 'PENDING_ACTIVATION' },
      { text: 'Active', value: 'ACTIVE' },
      { text: 'Expired', value: 'EXPIRED' },
    ],
    onFilter: (filter, item) => item.status === filter,
    render: (_, item) => {
      if (item.status === 'PENDING_ACTIVATION') return <Icon type="sync" spin />
      if (item.status === 'EXPIRED') return <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#d83f3f" />
      return <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    },
  },
  {
    title: 'Activation',
    dataIndex: 'activationTimeFmt',
    key: 'activationTime',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.activationTime || 0) - (b.activationTime || 0),
  },
  {
    title: 'Renewal',
    dataIndex: 'renewalTimeFmt',
    key: 'renewalTime',
    sorter: (a, b) => (a.renewalTime || 0) - (b.renewalTime || 0),
  },
  {
    title: 'Expiration',
    dataIndex: 'expirationTimeFmt',
    key: 'expirationTime',
    sorter: (a, b) => (a.expirationTime || 0) - (b.expirationTime || 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balanceAmount',
    key: 'balanceAmount',
    filters: [
      { text: 'With Balance', value: 'yes' },
      { text: 'Without Balance', value: 'no' },
    ],
    onFilter: (filter, item) => (filter === 'yes' ? !!item.balanceAmount : filter === 'no' ? !item.balanceAmount : true),
    sorter: (a, b) => (Number(a.balanceAmount) || 0) - (Number(b.balanceAmount) || 0),
    render: (balanceAmount) => <span>{formatCurrency(balanceAmount)}</span>,
  },
  {
    title: 'Profit',
    dataIndex: 'profitAmount',
    key: 'profitAmount',
    filters: [
      { text: 'With Profit', value: 'yes' },
      { text: 'Without Profit', value: 'no' },
    ],
    onFilter: (filter, item) =>
      filter === 'yes' ? !!item.profitAmount : filter === 'no' ? !item.profitAmount || sig(item.profitAmount) < 0 : true,
    sorter: (a, b) => (Number(a.profitAmount) || 0) - (Number(b.profitAmount) || 0),
    render: (profitAmount, item) => (
      <span>
        {formatCurrency(profitAmount)}
        {item.profitCurrencyCode ? `(${item.profitCurrencyCode})` : ''}
      </span>
    ),
  },
]

interface Props {
  licenses: Array<TableItem>
}
const LicensesTable: React.FunctionComponent<Props> = (props) => {
  const { onChangeHandler, downloadCsv } = useExportableTable(columns, props.licenses)
  return (
    <>
      <button onClick={downloadCsv}>Download CSV</button>
      <Table rowKey="licenseKey" dataSource={props.licenses} columns={columns} pagination={false} onChange={onChangeHandler} />
    </>
  )
}

export default LicensesTable
