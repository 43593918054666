import * as React from 'react'
import { Switch, Icon, notification } from 'antd'
import { blockMember, unblockMember } from '../../../services/api'

export interface MemberBlockSwitcherProps {
  loading?: boolean
  uid?: string
  blocked: boolean
}

export const MemberBlockSwitcher: React.FC<MemberBlockSwitcherProps> = ({ uid, blocked, loading }) => {
  const [switching, setSwitching] = React.useState(false)
  const switchBlockState = React.useCallback(async () => {
    if (switching) return
    if (!uid) return
    setSwitching(true)
    try {
      if (blocked) await unblockMember(uid)
      else await blockMember(uid)
    } catch (error) {
      notification.error({ message: 'Failed to switch block state of member! ' + (error as any).message })
    }
    setSwitching(false)
  }, [switching, setSwitching, blocked, uid])

  const isLoading = loading || switching
  return (
    <Switch
      loading={isLoading}
      checkedChildren={
        <>
          <Icon type="check" /> Active
        </>
      }
      unCheckedChildren={<> Blocked</>}
      checked={!blocked}
      onChange={switchBlockState}
    />
  )
}
