import * as React from 'react'
import { signInWithEmailAndPassword } from '../../utils/firebase-auth'

import SigninForm from './SignInForm.component'

const SigninPage: React.FunctionComponent = () => {
  const onSubmit = React.useCallback(async (props: { username: string; password: string }): Promise<void> => {
    await signInWithEmailAndPassword(props.username, props.password)
  }, [])
  return <SigninForm onSubmit={onSubmit} />
}

export default SigninPage
