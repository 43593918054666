import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../services/firebase'
// import { useRefreshMemberLang } from '../hooks/member'
import { useReportTokenRecognition } from '../hooks/auth'
import { lang } from '../i18n'

import DeskRoutes from './desk'

import LoadingSpinner from '../components/loading-spinner'
import SigninInkryptusPage from '../pages/signin'
import AuthLayout from '../layouts/auth'

const Routes: React.FunctionComponent = () => {
  const { initialising, user } = useAuthState(auth)

  const tokenReportLoading = useReportTokenRecognition()

  // useRefreshMemberLang()

  const loading: boolean = initialising || tokenReportLoading
  const authenticated: boolean = !!user

  if (loading) return <LoadingSpinner />

  if (!authenticated)
    return (
      <AuthLayout>
        <Switch>
          <Route exact path="/sign_in" component={SigninInkryptusPage} />
          <Redirect path="/" to="/sign_in" />
        </Switch>
      </AuthLayout>
    )

  return <DeskRoutes />
}

const EntrypointRoutes: React.FunctionComponent = () => {
  const { initialising } = useAuthState(auth)
  const loading = initialising
  React.useEffect(() => {
    document.body.classList.add(lang)
  }, [])
  if (loading) return <LoadingSpinner />
  return (
    <Switch>
      <Route path="/" component={Routes} />
    </Switch>
  )
}

export default EntrypointRoutes
