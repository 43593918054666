import React from 'react'
import { map, orderBy } from 'lodash'
import moment from 'moment'
import { Button, Table, Input, Icon } from 'antd'
import { TableRowSelection } from 'antd/lib/table'
import Fuse from 'fuse.js'
import { countries } from '../../utils/countries'
import { useExportableTable, TableColumnProps } from '../../hooks/table'
import { MemberProfileButton } from './MemberProfileButton'
import { useQueryParams } from '../../utils/url'
import { MemberOverviewModal } from './MemberOverviewModal/MemberOverviewModal'

export interface TableItem {
  uid: string
  username: string
  name: string
  lastName: string
  fullName: string
  email: string
  country: string
  creationTime: number | undefined
  sponsorUsername: string
  lang: string
}

const searchOptions: Fuse.IFuseOptions<TableItem> = {
  threshold: 0.25,
  keys: [
    {
      name: 'username',
      weight: 0.8,
    },
    {
      name: 'fullName',
      weight: 0.5,
    },
    {
      name: 'email',
      weight: 0.3,
    },
  ],
}

const columns: TableColumnProps<TableItem>[] = [
  {
    title: 'UID',
    dataIndex: 'uid',
    key: 'uid',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.username.localeCompare(b.username),
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsorUsername',
    key: 'sponsorUsername',
  },
  {
    title: 'Name',
    key: 'name',
    render: (_, item) => `${item.name} ${item.lastName}`,
    sorter: (a, b) => `${a.name} ${a.lastName}`.localeCompare(`${b.name} ${b.lastName}`),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, item) => countries[item.country],
    filters: orderBy(
      map(countries, (text, value) => ({ text, value })),
      ['text'],
      ['asc']
    ),
    onFilter: (filter, item) => item.country === filter,
  },
  {
    title: 'Lang',
    dataIndex: 'lang',
    key: 'lang',
  },
  {
    title: 'Registered at',
    dataIndex: 'creationTime',
    key: 'creationTime',
    sorter: (a, b) => (a.creationTime || 0) - (b.creationTime || 0),
    render: (_, item) => (item.creationTime ? moment(item.creationTime).format('L LT') : '-'),
  },
  {
    title: '',
    dataIndex: 'uid',
    key: 'profileButton',
    render: (_, item) => <MemberProfileButton username={item.username} />,
  },
]

interface Props {
  members: Array<TableItem>
  loading?: boolean
  rowSelection?: TableRowSelection<TableItem>
  onNotifyMembersClick?: () => void
  onNotifyMemberClick?: (username: string) => void
  onSelectAllClick?: () => void
}
const MembersTable: React.FunctionComponent<Props> = (props) => {
  const [queryParams, setQueryParams] = useQueryParams()
  const memberOverviewUsername = queryParams.get('member_overview_username') || undefined
  const closeMemberOverview = React.useCallback(() => {
    setQueryParams((s) => {
      s.delete('member_overview_username')
    })
  }, [setQueryParams])

  const { onChangeHandler, downloadCsv } = useExportableTable(columns, props.members)
  const members = props.members

  const [searchQuery, setSearchQuery] = React.useState('')
  const changeSearchQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
    },
    [setSearchQuery]
  )
  const isSearch = !!searchQuery
  const searchResults: Array<TableItem> = React.useMemo(() => {
    if (!searchQuery) return members
    if (searchQuery.length <= 2) return []
    const fuse = new Fuse(members, searchOptions)
    const results = fuse.search(searchQuery)
    return results.map((r) => r.item)
  }, [searchQuery, members])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MemberOverviewModal username={memberOverviewUsername} onClose={closeMemberOverview} onNotifyMemberClick={props.onNotifyMemberClick} />
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 15,
          height: 62,
        }}
      >
        <Button style={{ marginRight: 16 }} onClick={downloadCsv}>
          Download CSV
        </Button>
        <Button
          style={{ marginRight: 16 }}
          onClick={props.onSelectAllClick}
          disabled={!props.onSelectAllClick || !members.length}
          type={props.rowSelection?.selectedRowKeys?.length === members.length ? 'primary' : undefined}
        >
          Select All({members.length || 0})
        </Button>
        <Button
          style={{ marginRight: 16 }}
          onClick={props.onNotifyMembersClick}
          disabled={!props.onNotifyMembersClick || !props.rowSelection?.selectedRowKeys?.length}
        >
          Send Notification({props.rowSelection?.selectedRowKeys?.length || 0})
        </Button>
        <Input
          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Search..."
          value={searchQuery}
          onChange={changeSearchQuery}
        />
      </div>
      <Table
        dataSource={isSearch ? searchResults : members}
        loading={props.loading}
        rowKey="username"
        columns={columns}
        pagination={isSearch ? false : undefined}
        onChange={onChangeHandler}
        rowSelection={props.rowSelection}
      />
    </div>
  )
}

export default MembersTable
