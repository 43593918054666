import React from 'react'
import { Layout } from 'antd'
import Header from './Header.container'
import Footer from './Footer.component'

const AdminLayout: React.FC = (props) => {
  return (
    <Layout className="admin-layout">
      <Header />
      <Layout.Content className="admin-content">{props.children}</Layout.Content>
      <Footer />
    </Layout>
  )
}

export default AdminLayout
