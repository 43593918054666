import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, AuthPersistence } from '../services/firebase'
import { reportTokenRecognition } from '../services/api'
import alrt from '../utils/alrt'
import { t } from '../i18n'

export function useUid(): [string | null, boolean] {
  const { user, initialising } = useAuthState(auth)
  const uid = user ? user.uid : null
  return [uid, initialising]
}

export function useReportTokenRecognition(): boolean {
  const [jwt, setJwt] = useState<string | null>(null)
  const { initialising, user } = useAuthState(auth)
  const [loading, setLoading] = useState<boolean>(false)

  // persist jwt from query params
  const urlParamJwt = new URLSearchParams(window.location.search).get('jwt')
  useEffect(() => {
    if (!urlParamJwt) return
    setJwt(urlParamJwt)
  }, [urlParamJwt])

  useEffect(() => {
    if (!jwt) return
    if (!loading) setLoading(true)
    if (initialising) return
    ;(async () => {
      try {
        await auth.setPersistence(AuthPersistence.SESSION)
        const decodedToken = await auth.signInWithCustomToken(jwt)
        if (!decodedToken.user) return
        const idToken = await decodedToken.user.getIdToken()
        await reportTokenRecognition(idToken)
      } catch (error) {
        console.error(error)
        if (error.humanizedMessage) alrt.error({ body: error.humanizedMessage })
        else alrt.error({ body: t('errors:auth_invalid_jwt') as string })
      }
      setLoading(false)
    })()
    setJwt(null)
  }, [jwt, user, initialising])
  return loading
}
