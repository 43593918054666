import { Alert, Modal, notification, Input } from 'antd'
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { sendNotificationMessage } from '../../services/api'

export interface MembersNotificationSenderModalProps {
  usernames?: string[]
  show?: boolean
  onClose: () => any
}

const quillModules = {
  toolbar: [[], ['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['link'], ['clean']],
}

export const MembersNotificationSenderModal: React.FC<MembersNotificationSenderModalProps> = ({ usernames, show, onClose }) => {
  const [errorMessage, setErrorMessage] = React.useState('')
  const dismissErrorMessage = React.useCallback(() => setErrorMessage(''), [setErrorMessage])

  const close = React.useCallback(() => {
    setErrorMessage('')
    onClose()
  }, [onClose, setErrorMessage])

  const [title, setTitle] = React.useState('')
  const changeTitle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value)
    },
    [setTitle]
  )

  const [messageBody, setMessageBody] = React.useState('')

  const [sending, setSending] = React.useState(false)
  const sendNotification = React.useCallback(async () => {
    if (sending) return
    if (!messageBody || !title) return
    if (!usernames || !usernames.length) return
    setSending(true)
    setErrorMessage('')
    try {
      await sendNotificationMessage(usernames, title, messageBody)
      close()
      notification.success({
        message: `${usernames.length} member(s) notified successfully!`,
        // description: `${contractsNonProfitableCount} skipped because their is running first empty cycle.`,
      })
      setTitle('')
      setMessageBody('')
    } catch (error) {
      setErrorMessage((error as any).message)
    }
    setSending(false)
  }, [sending, setSending, setErrorMessage, close, usernames, messageBody, setMessageBody, title, setTitle])

  return (
    <Modal
      width={700}
      title="Members Notification Sender"
      visible={show}
      onOk={sendNotification}
      okButtonProps={{ disabled: !usernames || !usernames.length || !messageBody }}
      confirmLoading={sending}
      onCancel={onClose}
    >
      <div style={{ marginBottom: 16 }}>
        {errorMessage && (
          <Alert message="Failed to Send Notification" description={errorMessage} type="error" closable onClose={dismissErrorMessage} />
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        <Input placeholder="Message Title" value={title} onChange={changeTitle} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <ReactQuill
          theme="snow"
          style={{ fontFamily: '"Signika", sans-serif' }}
          placeholder="Message Body"
          value={messageBody}
          onChange={setMessageBody}
          modules={quillModules}
        />
      </div>
    </Modal>
  )
}
