import enUS from './en-US.json'
import ptBR from './pt-BR.json'
import esES from './es-ES.json'
import ruRU from './ru-RU.json'
import 'moment/locale/pt-br'
import 'moment/locale/es'
import 'moment/locale/ru'

export const AVAILABLE_LOCALES = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES,
  'ru-RU': ruRU,
}

export const DEFAULT_LANGUAGE = 'en-US'

export function resolveLanguage(lang?: string | null): string {
  if (!lang) return DEFAULT_LANGUAGE
  const selectMainLanguage = (lang: string) => lang.slice(0, 2)
  const mainLanguage = selectMainLanguage(lang)
  const selectDialect = (lang: string) => lang.slice(3, 5) || null
  const dialect = selectDialect(lang)
  switch (mainLanguage) {
    case 'en':
      switch (dialect) {
        case 'US':
        default:
          return 'en-US'
      }
    case 'pt':
      switch (dialect) {
        case 'BR':
        default:
          return 'pt-BR'
      }
    case 'es':
      switch (dialect) {
        case 'ES':
        default:
          return 'es-ES'
      }
    case 'ru':
      switch (dialect) {
        case 'RU':
        default:
          return 'ru-RU'
      }
    default:
      return DEFAULT_LANGUAGE
  }
}
