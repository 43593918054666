import Moment from 'react-moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { toLower } from 'lodash'

export * from 'react-moment'

const defaultLang = toLower('pt-BR')

moment.locale(defaultLang)
Moment.globalMoment = moment
Moment.globalLocale = defaultLang
Moment.startPooledTimer()

export { Moment }
export default moment
