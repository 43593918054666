import React from 'react'
import { Row, Col, Card, Icon, Statistic } from 'antd'
import { sig } from '@allcoinwallet/invest-bitcoin-decimal'
import { formatDecimal } from '../../utils/format'
import { useCompanyWallet } from '../../modules/wallets'

export interface ContractBalanceWidgetsProps {
  type: 'stake' | 'swap'
}

const ContractBalanceWidgets: React.FC<ContractBalanceWidgetsProps> = ({ type }) => {
  const symbol = 'BNB'

  const [contractWallet, contractWalletLoading] = useCompanyWallet(type, 'contract', symbol)
  const contractBalance = contractWallet?.balance.available
  const [processorWallet, processorWalletLoading] = useCompanyWallet(type, 'processor', symbol)
  const processorBalance = processorWallet?.balance.available
  const [vaultWallet, vaultWalletLoading] = useCompanyWallet(type, 'vault', symbol)
  const vaultBalance = vaultWallet?.balance.available

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Card loading={contractWalletLoading}>
            <Statistic
              title="Contract Owner"
              value={contractBalance ? formatDecimal(contractBalance, 8) : '-'}
              valueStyle={contractBalance && sig(contractBalance) > 0 ? { color: '#1890ff' } : { opacity: 0.5 }}
              prefix={<Icon type="audit" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card loading={processorWalletLoading}>
            <Statistic
              title="Contract Processor"
              value={processorBalance ? formatDecimal(processorBalance, 8) : '-'}
              valueStyle={processorBalance && sig(processorBalance) > 0 ? { color: '#1890ff' } : { opacity: 0.5 }}
              prefix={<Icon type="code" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card loading={vaultWalletLoading}>
            <Statistic
              title="Contract Vault"
              value={vaultBalance ? formatDecimal(vaultBalance, 8) : '-'}
              valueStyle={vaultBalance && sig(vaultBalance) ? { color: '#1890ff' } : { opacity: 0.5 }}
              prefix={<Icon type="fund" />}
              suffix={symbol}
              decimalSeparator="."
              groupSeparator=","
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ContractBalanceWidgets
