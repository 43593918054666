import * as React from 'react'
import { List, Skeleton } from 'antd'
import { useWalletTransaction } from '../../../modules/wallets'

export interface LockTransactionListItemProps {
  type: 'stake' | 'swap'
  transactionId: string
}

export const LockTransactionListItem: React.FC<LockTransactionListItemProps> = ({ type, transactionId }) => {
  const [transaction, transactionLoading] = useWalletTransaction(type === 'stake' ? transactionId : undefined)
  const loading = transactionLoading
  return (
    <List.Item
      actions={[<a key="list-loadmore-edit">preview</a>, <a key="list-loadmore-more">abort</a>, <a key="list-loadmore-more">confirm</a>]}
    >
      <Skeleton title={false} loading={loading} active>
        <List.Item.Meta title={<a href="#">{transactionId}</a>} description={`${transaction?.amount || ''}`} />
      </Skeleton>
    </List.Item>
  )
}
