import { ProductLicenses } from '@allcoinwallet/inkryptus-db-schema'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../services/firebase'
import { useDatabaseObject } from '../hooks/database'

type LicensesHook = () => [ProductLicenses | null, boolean, object | undefined]
export const useLicenses: LicensesHook = () => {
  const { user } = useAuthState(auth)
  const licensesRef = user ? db.child('productLicenses') : undefined
  const { value: licenses, loading, error } = useDatabaseObject(licensesRef)
  return [licenses || null, loading, error]
}
