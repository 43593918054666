import * as React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { Modal } from 'antd'
import { size } from 'lodash'

export interface AvailableOwnersListModalProps {
  state?: {
    type: 'stake' | 'swap'
    symbol: string
    availableOwners: { [userId: string]: decimal }
  }
  onClose?: () => void
}

export const AvailableOwnersListModal: React.FC<AvailableOwnersListModalProps> = ({ state, onClose }) => {
  const owners = state?.availableOwners
  const ownersCount = React.useMemo(() => size(owners), [owners])
  return (
    <Modal
      title={`${ownersCount} Owner${ownersCount !== 1 ? 's' : ''} of available balance`}
      visible={!!state}
      onCancel={onClose}
      footer={null}
      className="available-owners-list-modal"
    ></Modal>
  )
}
