import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import { root, Reference, Database } from '@allcoinwallet/inkryptus-db-schema'
import config from '../config'

const firebaseApp = firebase.initializeApp(config.firebase)

const db = root(firebaseApp.database() as any) as Reference<Database>
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const projectId = config.firebase.projectId

export async function dbGet<T>(ref: Reference<T>): Promise<T | null> {
  const snapshot = await ref.once('value')
  if (!snapshot.exists()) return null
  return snapshot.val()
}

auth.onAuthStateChanged(async (user) => {
  if (!user) return
  const idToken = await user.getIdToken()
  console.log('Auth id-token:', idToken)
})

export type FirebaseError = firebase.FirebaseError

export const AuthPersistence = firebase.auth.Auth.Persistence

export { db, auth, storage, projectId }

export default firebaseApp
