import React from 'react'
import MembersManagementTable from './MembersManagementTable.container'

const MembersPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-members">
      <div>
        <MembersManagementTable />
      </div>
    </div>
  )
}

export default MembersPage
