import i18n, { TFunction } from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import { AVAILABLE_LOCALES } from './locales'
import { getLanguage, getDialect } from './persistence'

const lang = getLanguage()
const dialect = getDialect()
console.log('User preferred language:', lang)
moment.locale(lang)

let t: TFunction = i18n.t.bind(i18n)
i18n
  .use(initReactI18next)
  .init({
    resources: AVAILABLE_LOCALES,
    lng: lang,
    fallbackLng: 'en-US',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    appendNamespaceToCIMode: true,
    debug: process.env.NODE_ENV === 'development',
  })
  .then((tFn) => {
    t = tFn
  })

export { t, lang, dialect }

export * from './locales'
export * from './persistence'

export default i18n
