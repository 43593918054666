import React from 'react'
import ProductsContractsManagementTable from './ProductsContractsManagementTable.container'

const ProductsContractsPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-products-contracts">
      <div>
        <ProductsContractsManagementTable />
      </div>
    </div>
  )
}

export default ProductsContractsPage
