import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'
import './styles/main.scss'

import './i18n'

const rootElement = document.getElementById('root') as HTMLElement

Modal.defaultStyles = { overlay: {}, content: {} }
Modal.setAppElement(rootElement)

ReactDOM.render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  rootElement
)

serviceWorker.unregister()
