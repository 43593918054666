import React from 'react'
import { map, split, first } from 'lodash'
import moment from 'moment'
import LicensesTable from './LicensesTable.component'
import { Licenses } from '../../modules'
import { formatUpperProductId } from '../../utils/format'

const LicensesManagementTable: React.FunctionComponent = () => {
  const [licenses] = Licenses.useLicenses()
  const licensesDataSource = React.useMemo(() => {
    if (!licenses) return []
    return map(licenses, (l, licenseKey) => ({
      licenseKey,
      licenseKeyAlias: first(split(licenseKey, '-')) || '',
      productAlias: formatUpperProductId(l.productId),
      username: l.username,
      email: l.email,
      status: l.status,
      activationTime: l.activationTime || null,
      expirationTime: l.expirationTime || null,
      renewalTime: l.renewalActivationTime || null,
      activationTimeFmt: l.activationTime ? moment(l.activationTime).format('L LT') : '-',
      expirationTimeFmt: l.expirationTime ? moment(l.expirationTime).format('L LT') : '-',
      renewalTimeFmt: l.renewalActivationTime ? moment(l.renewalActivationTime).format('L LT') : '-',
      balanceAmount: l.operation ? l.operation.totalBalance : null,
      profitCurrencyCode: l.lastProfitFee ? l.lastProfitFee.profitCurrencyCode : null,
      profitAmount: l.lastProfitFee ? l.lastProfitFee.profitAmount : null,
    }))
  }, [licenses])
  return <LicensesTable licenses={licensesDataSource} />
}

export default LicensesManagementTable
