import { decimal, Decimal, add, mul, trunc, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { Alert, Descriptions, Input, Modal, notification } from 'antd'
import { map } from 'lodash'
import React from 'react'
import { getProductIdBySymbol, reportProductsContractsProfit } from '../../services/api'
import { ProductContractRow } from './all-products-contracts.hook'

export interface ProductsContractsProfitReportModalProps {
  contracts: ProductContractRow[]
  show?: boolean
  onClose: () => any
}

export const ProductsContractsProfitReport: React.FC<ProductsContractsProfitReportModalProps> = ({ contracts, show, onClose }) => {
  const [contractsCount, contractsNetAmount, contractsNonProfitableCount] = React.useMemo((): [number, decimal, number] => {
    let netAmount = Decimal(0)
    let nonProfitableCount = 0
    for (const contract of contracts) {
      if (contract.lastProfit === undefined) nonProfitableCount += 1
      else netAmount = add(netAmount, contract.netAmount)
    }
    return [contracts.length, netAmount, nonProfitableCount]
  }, [contracts])

  const [profitPercent, setProfitPercent] = React.useState('')
  const changeProfitPercent = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setProfitPercent(event.target.value)
    },
    [setProfitPercent]
  )
  const formatProfitPercent = React.useCallback(() => {
    setProfitPercent((v) => {
      try {
        return Decimal(v)
      } catch (error) {
        return ''
      }
    })
  }, [setProfitPercent])
  const profit = React.useMemo(() => {
    try {
      if (!profitPercent) return
      const percent = Decimal(profitPercent)
      return mul(percent, Decimal(0.01))
    } catch (error) {
      return
    }
  }, [profitPercent])

  const [newProfitNetAmount, newProfitFeeAmount, totalAmountAfterProfit, newProfitTotalAmount] = React.useMemo((): [
    decimal,
    decimal,
    decimal,
    decimal
  ] => {
    let profitNetAmount = Decimal(0)
    let profitFeeAmount = Decimal(0)
    let profitTotalAmount = Decimal(0)
    if (profit) {
      const totalProfitAmount = trunc(mul(contractsNetAmount, profit), 18)
      profitFeeAmount = trunc(mul(totalProfitAmount, Decimal(0.25)), 18)
      profitNetAmount = sub(totalProfitAmount, profitFeeAmount)
      profitTotalAmount = add(profitTotalAmount, totalProfitAmount)
    }
    const totalAmount = add(add(profitNetAmount, profitFeeAmount), contractsNetAmount)
    return [profitNetAmount, profitFeeAmount, totalAmount, profitTotalAmount]
  }, [contractsNetAmount, profit])

  const [profitReportErrorMessage, setProfitReportErrorMessage] = React.useState('')
  const dismissErrorMessage = React.useCallback(() => setProfitReportErrorMessage(''), [setProfitReportErrorMessage])

  const close = React.useCallback(() => {
    setProfitReportErrorMessage('')
    onClose()
  }, [onClose, setProfitReportErrorMessage])

  const [reportingProfit, setReportingProfit] = React.useState(false)
  const reportProfit = React.useCallback(async () => {
    if (reportingProfit) return
    if (!profit) return
    setReportingProfit(true)
    try {
      const contractsIds = map(contracts, 'contractId')
      const sampleContract = contracts[0]
      const symbol = sampleContract.symbol
      let productId
      if (symbol === 'INKY') {
        productId = 'inky-super-pool-12month'
      } else {
        productId = getProductIdBySymbol(symbol)
      }

      await reportProductsContractsProfit(productId, contractsIds, profit)
      close()
      notification.success({
        message: `${contractsCount} contract(s) profit reported successfully!`,
        description: `${contractsNonProfitableCount} skipped because their is running first empty cycle.`,
      })
      setProfitPercent('')
    } catch (error) {
      setProfitReportErrorMessage((error as any).message)
    }
    setReportingProfit(false)
  }, [
    reportingProfit,
    setReportingProfit,
    setProfitReportErrorMessage,
    close,
    profit,
    contracts,
    contractsCount,
    contractsNonProfitableCount,
    setProfitPercent,
  ])

  return (
    <Modal
      width={700}
      title="Products Contracts Profit Cycle"
      visible={show}
      onOk={reportProfit}
      okButtonProps={{ disabled: !profit }}
      confirmLoading={reportingProfit}
      onCancel={onClose}
    >
      <div style={{ marginBottom: 16 }}>
        {profitReportErrorMessage && (
          <Alert message="Failed to Report Profit" description={profitReportErrorMessage} type="error" closable onClose={dismissErrorMessage} />
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        <Input addonAfter="%" value={profitPercent} onChange={changeProfitPercent} onBlur={formatProfitPercent} />
      </div>
      <div>
        <Descriptions title="Cycle Impact Feedback">
          <Descriptions.Item label="No of Contracts">{contractsCount}</Descriptions.Item>
          <Descriptions.Item label="Non Profitable Contracts">{contractsNonProfitableCount}</Descriptions.Item>
          <Descriptions.Item label="Profit Factor">{profit}</Descriptions.Item>
          <Descriptions.Item label="Net Amount(profit basis)">{contractsNetAmount}</Descriptions.Item>
          <Descriptions.Item label="Cycle Profit Net Amount(75%)">{newProfitNetAmount}</Descriptions.Item>
          <Descriptions.Item label="Cycle Profit Fee Amount(25%)">{newProfitFeeAmount}</Descriptions.Item>
          <Descriptions.Item label="Cycle Profit Total Amount">{newProfitTotalAmount}</Descriptions.Item>
          <Descriptions.Item label="Total Amount After Cycle">{totalAmountAfterProfit}</Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  )
}
