import React from 'react'
import { map } from 'lodash'
import { decimal, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { Profile, Query, SwapWalletsTransactions, SwapWalletTransaction, SwapWalletTransactionType } from '@allcoinwallet/inkryptus-db-schema'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useQueryParams } from '../../utils/url'
import { auth, db } from '../../services/firebase'
import { useDatabaseObject } from '../../hooks/database'
import { useMembersProfile, useMembersStatuses } from '../../modules/members'

export type SwapWalletTransactionRow = SwapWalletTransaction & {
  transactionId: string
  netAmount: decimal
  username?: string
  profile?: Profile
}

type UseAllWalletsTransactionsHook = (type?: SwapWalletTransactionType) => [Array<SwapWalletTransactionRow>, boolean, object | undefined]
const useAllWalletsTransactions: UseAllWalletsTransactionsHook = (type) => {
  const [queryParams] = useQueryParams()
  const statusFilter = queryParams.get('status')

  const [membersStatuses, membersStatusesLoading] = useMembersStatuses()
  const [membersProfile, membersProfileLoading] = useMembersProfile()

  const { user } = useAuthState(auth)
  let transactionsRef: Query<SwapWalletsTransactions> | undefined = user ? db.child('swapWalletsTransactions') : undefined
  if (type && statusFilter === 'PENDING_CONFIRMATION' && transactionsRef)
    transactionsRef = transactionsRef.orderByChild('_indexes/type__authorized__confirmationTime').equalTo(`${type}__true__undefined`)
  const { value: transactionsCollection, loading: transactionsLoading, error } = useDatabaseObject(transactionsRef)

  const transactions: Array<SwapWalletTransactionRow> = React.useMemo(() => {
    if (!transactionsCollection) return []
    return map(transactionsCollection, (transaction, transactionId) => {
      const [username, profile] = getMemberProfile(transaction.userId)
      return {
        ...transaction,
        transactionId,
        username,
        profile,
        netAmount: sub(transaction.amount, transaction.feeAmount),
      }
    })
    function getMemberProfile(userId: string): [string, Profile] | [undefined, undefined] {
      if (!membersStatuses || !membersStatuses[userId]) return [undefined, undefined]
      const username = membersStatuses[userId].username
      if (!membersProfile || !membersProfile[username]) return [undefined, undefined]
      return [username, membersProfile[username]]
    }
  }, [transactionsCollection, membersStatuses, membersProfile])

  const loading = transactionsLoading || membersStatusesLoading || membersProfileLoading

  return [transactions, loading, error]
}

export default useAllWalletsTransactions
