import { trunc, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { Alert, Descriptions, Input, Modal, notification } from 'antd'
import React from 'react'
import { swapWalletsReportBroadcastedWithdrawal } from '../../services/api'
import { SwapWalletTransactionRow } from './all-wallets-transactions.hook'

export interface SwapWalletTransactionReportBroadcastedModalProps {
  transaction?: SwapWalletTransactionRow
  show?: boolean
  onClose: () => any
}

export const SwapWalletTransactionReportBroadcastedModal: React.FC<SwapWalletTransactionReportBroadcastedModalProps> = ({
  transaction,
  onClose,
}) => {
  const [transactionHash, setTransactionHash] = React.useState('')
  const changeTransactionHash = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTransactionHash(event.target.value)
    },
    [setTransactionHash]
  )

  const [reportErrorMessage, setReportErrorMessage] = React.useState('')
  const dismissErrorMessage = React.useCallback(() => setReportErrorMessage(''), [setReportErrorMessage])

  const close = React.useCallback(() => {
    setReportErrorMessage('')
    setTransactionHash('')
    onClose()
  }, [onClose, setReportErrorMessage])

  const [reporting, setReporting] = React.useState(false)
  const reportBroadcasted = React.useCallback(async () => {
    if (reporting) return
    if (!transaction) return
    if (!transactionHash) return
    setReporting(true)
    try {
      await swapWalletsReportBroadcastedWithdrawal(transaction.symbol, transaction.transactionId, transactionHash)
      close()
      notification.success({ message: 'Withdrawal successfully reported as broadcasted!' })
    } catch (error) {
      setReportErrorMessage((error as any).message)
    }
    setReporting(false)
  }, [reporting, setReporting, setReportErrorMessage, close, transaction, transactionHash])

  return (
    <Modal
      width={700}
      title={`${transaction?.symbol} Swap Withdrawal Report as Broadcasted`}
      visible={!!transaction}
      onOk={reportBroadcasted}
      okButtonProps={{ disabled: !transactionHash }}
      confirmLoading={reporting}
      onCancel={onClose}
    >
      <div style={{ marginBottom: 16 }}>
        {reportErrorMessage && (
          <Alert
            message="Failed to Report as Broadcasted"
            description={reportErrorMessage}
            type="error"
            closable
            onClose={dismissErrorMessage}
          />
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        <Input value={transactionHash} onChange={changeTransactionHash} />
      </div>
      <div>
        {!!transaction && (
          <>
            <Descriptions title="Transaction Info">
              <Descriptions.Item label="Gross Amount">{trunc(transaction.amount, 18)}</Descriptions.Item>
              <Descriptions.Item label="Fee Amount">{trunc(transaction.feeAmount, 18)}</Descriptions.Item>
              <Descriptions.Item label="Net Amount">{trunc(sub(transaction.amount, transaction.feeAmount), 18)}</Descriptions.Item>
              <Descriptions.Item label="Symbol">{transaction?.symbol}</Descriptions.Item>
              <Descriptions.Item label="Address">{transaction.externalAddress}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Member Info">
              <Descriptions.Item label="Username">{transaction.username}</Descriptions.Item>
              <Descriptions.Item label="Name">
                {transaction.profile?.name} {transaction.profile?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{transaction.profile?.email}</Descriptions.Item>
              <Descriptions.Item label="User Id">{transaction.userId}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </div>
    </Modal>
  )
}
