import React from 'react'
import { Button, notification, Popconfirm } from 'antd'
import { TableRowSelection } from 'antd/lib/table'
import { map, size } from 'lodash'
import { Decimal, decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { WalletTransactionType } from '@allcoinwallet/inkryptus-db-schema'

import WalletsTransactionsTable from './WalletsTransactionsTable.component'
import useAllWalletsTransactions, { WalletTransactionRow } from './all-wallets-transactions.hook'
import { useQueryParams } from '../../utils/url'
import { WalletTransactionReportBroadcastedModal } from './WalletTransactionReportBroadcastedModal'
import { walletsAbortTransaction, walletsReportConfirmedHarvest } from '../../services/api'

const WalletsTransactionsManagementTable: React.FunctionComponent = () => {
  const [queryParams] = useQueryParams()
  const typeFilter: WalletTransactionType | undefined = React.useMemo(
    () =>
      ['withdrawal', 'deposit', 'product_contract_harvest'].includes(queryParams.get('type') || '')
        ? (queryParams.get('type') as WalletTransactionType)
        : undefined,
    []
  )

  const [transactions] = useAllWalletsTransactions(typeFilter)

  const [selectedTransactions, setSelectedTransactions] = React.useState<WalletTransactionRow[]>([])
  const rowSelection: TableRowSelection<WalletTransactionRow> = React.useMemo(() => {
    const selectedRowKeys = map(selectedTransactions, 'transactionId')
    return {
      selectedRowKeys,
      onChange: (keys, rows) => {
        // console.log('select change args', keys, rows, selectedRowKeys)
        // debugger
        setSelectedTransactions(rows || [])
      },
      getCheckboxProps: (txn) => ({
        disabled: !!txn.failureReason || !!txn.confirmationTime,
      }),
    }
  }, [selectedTransactions, setSelectedTransactions])

  const [usersOfSelectedTransactions, selectedTransactionsAmount] = React.useMemo((): [number, { [symbol: string]: decimal }] => {
    const uniqueUsers: { [userId: string]: boolean } = {}
    const total: { [symbol: string]: decimal } = {}
    for (const transaction of selectedTransactions) {
      uniqueUsers[transaction.userId] = true
      if (!total[transaction.symbol]) total[transaction.symbol] = Decimal(0)
      total[transaction.symbol] = add(total[transaction.symbol], transaction.amount)
    }
    return [size(uniqueUsers), total]
  }, [selectedTransactions])
  const selectedTransactionsAmountJson = React.useMemo(() => JSON.stringify(selectedTransactionsAmount), [selectedTransactionsAmount])

  const [withdrawingTransaction, setWithdrawingTransaction] = React.useState<WalletTransactionRow>()
  const openReportBroadcastedModal = React.useCallback(
    (transaction: WalletTransactionRow) => {
      if (transaction.confirmationTransactionHash) return
      setWithdrawingTransaction(transaction)
    },
    [setWithdrawingTransaction]
  )
  const hideReportBroadcastedModal = React.useCallback(() => {
    setWithdrawingTransaction(undefined)
  }, [setWithdrawingTransaction])

  const [abortingTransactions, setAbortingTransactions] = React.useState(false)
  const abortTransactions = React.useCallback(async () => {
    if (abortingTransactions) return
    setAbortingTransactions(true)
    for (const transaction of selectedTransactions) {
      try {
        await walletsAbortTransaction(transaction.symbol, transaction.transactionId)
        setSelectedTransactions((rows) => rows.filter((txn) => txn.transactionId !== transaction.transactionId))
        notification.success({ message: `Aborted transaction ${transaction.transactionId} successfully!` })
      } catch (error) {
        notification.error({ message: `Failed to abort transaction ${transaction.transactionId}!` })
      }
    }
    setAbortingTransactions(false)
  }, [abortingTransactions, setAbortingTransactions, selectedTransactions, setSelectedTransactions])

  const [confirmingHarvestTransactions, setConfirmingHarvestTransactions] = React.useState(false)
  const confirmHarvestTransactions = React.useCallback(async () => {
    if (confirmingHarvestTransactions) return
    setConfirmingHarvestTransactions(true)
    for (const transaction of selectedTransactions) {
      if (transaction.type !== 'product_contract_harvest') {
        notification.warn({ message: `Skipped ${transaction.transactionId} because of that isn\'t a harvest request transaction!` })
      }
      try {
        await walletsReportConfirmedHarvest(transaction.symbol, transaction.transactionId)
        setSelectedTransactions((rows) => rows.filter((txn) => txn.transactionId !== transaction.transactionId))
        notification.success({ message: `Confirmed harvest transaction ${transaction.transactionId} successfully!` })
      } catch (error) {
        notification.error({ message: `Failed to confirm harvest transaction ${transaction.transactionId}!` })
      }
    }
    setConfirmingHarvestTransactions(false)
  }, [confirmingHarvestTransactions, setConfirmingHarvestTransactions, selectedTransactions, setSelectedTransactions])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          padding: 15,
          height: 62,
        }}
      >
        <Popconfirm
          placement="bottom"
          title={`Do you confirm the action to \"Abort Transactions\"? (irreversible)`}
          onConfirm={abortTransactions}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" style={{ marginRight: 16 }} disabled={!selectedTransactions.length} loading={abortingTransactions}>
            Abort Transactions ({selectedTransactions.length})
          </Button>
        </Popconfirm>

        {typeFilter === 'product_contract_harvest' && (
          <Popconfirm
            placement="bottom"
            title={`Do you confirm the action to \"Confirm Transactions\"? (irreversible)`}
            onConfirm={confirmHarvestTransactions}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" style={{ marginRight: 16 }} disabled={!selectedTransactions.length} loading={confirmingHarvestTransactions}>
              Confirm Harvest Transactions ({selectedTransactions.length})
            </Button>
          </Popconfirm>
        )}
        <span>
          Users of Selected Txs: {usersOfSelectedTransactions} | Total amount of Selected Txs: {selectedTransactionsAmountJson}
        </span>
      </div>
      <WalletsTransactionsTable transactions={transactions} rowSelection={rowSelection} onRowClick={openReportBroadcastedModal} />
      <WalletTransactionReportBroadcastedModal onClose={hideReportBroadcastedModal} transaction={withdrawingTransaction} />
    </div>
  )
}

export default WalletsTransactionsManagementTable
