import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Layout, Icon, Button, Menu } from 'antd'
import { signOut } from '../../utils/firebase-auth'

const Header: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { history } = props
  const openMenuPage = React.useCallback((route: string, params?: any) => {
    return () => props.history.push(route, params)
  }, [])

  const goToHome = React.useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <Layout.Header className="admin-header">
      <div>
        <img src="/logo-inkryptus-light.svg" onClick={goToHome} />
      </div>
      <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px', width: '100%' }}>
        <Menu.Item key="members" onClick={openMenuPage('/members')}>
          Members
        </Menu.Item>
        {/* <Menu.Item key="licenses" onClick={openMenuPage('/licenses')}>
          Licenses
        </Menu.Item> */}
        {/* <Menu.Item key="products" onClick={openMenuPage('/products')}>
          Products
        </Menu.Item> */}
        {/* <Menu.Item key="earnings" onClick={openMenuPage('/earnings')}>
          Earnings
        </Menu.Item> */}

        <Menu.SubMenu key="contracts_to_activate" title="Contracts To Activate">
          <Menu.Item key="contracts_to_activate:CAKE" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=CAKE')}>
            CAKE
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:BANANA" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=BANANA')}>
            BANANA
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:BSW" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=BSW')}>
            BSW
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:OLEBUSD" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=OLEBUSD')}>
            OLE/BUSD
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:WMXBUSD" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=WMXBUSD')}>
            WMX/BUSD
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:KRSBUSD" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=KRSBUSD')}>
            KRS/BUSD
          </Menu.Item>
          <Menu.Item
            key="contracts_to_activate:SQUADCAKE"
            onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=SQUADCAKE')}
          >
            SQUAD/CAKE
          </Menu.Item>
          <Menu.Item key="contracts_to_activate:INKY" onClick={openMenuPage('/products-contracts?status=PENDING_ACTIVATION&symbol=INKY')}>
            INKY
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="profitable_contracts" title="Profitable Contracts">
          <Menu.Item
            key="profitable_contracts:CAKE"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=CAKE&productId=cake-smart-farm')}
          >
            CAKE
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:BANANA"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=BANANA&productId=banana-smart-farm')}
          >
            BANANA
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:BSW"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=BSW&productId=biswap-smart-farm')}
          >
            BSW
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:OLEBUSD"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=OLEBUSD&productId=ole-busd-pool')}
          >
            OLE/BUSD
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:WMXBUSD"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=WMXBUSD&productId=wmx-busd-pool')}
          >
            WMX/BUSD
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:KRSBUSD"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=KRSBUSD&productId=krs-busd-pool')}
          >
            KRS/BUSD
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:SQUADCAKE"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=SQUADCAKE&productId=squad-cake-pool')}
          >
            SQUAD/CAKE
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:INKY"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=INKY&productId=inky-super-pool-6month')}
          >
            INKY6
          </Menu.Item>
          <Menu.Item
            key="profitable_contracts:INKY"
            onClick={openMenuPage('/products-contracts?status=ACTIVE&symbol=INKY&productId=inky-super-pool-12month')}
          >
            INKY12
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="contracts_to_deactivate" title="Contracts To Deactivate">
          <Menu.Item key="contracts_to_deactivate:CAKE" onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=CAKE')}>
            CAKE
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:BANANA"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=BANANA')}
          >
            BANANA
          </Menu.Item>
          <Menu.Item key="contracts_to_deactivate:BSW" onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=BSW')}>
            BSW
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:OLEBUSD"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=OLEBUSD')}
          >
            OLE/BUSD
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:GRVSBUSD"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=GRVSBUSD')}
          >
            GRVS/BUSD
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:VTGBNB"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=VTGBNB')}
          >
            VTG/BNB
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:WMXBUSD"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=WMXBUSD')}
          >
            WMX/BUSD
          </Menu.Item>

          <Menu.Item
            key="contracts_to_deactivate:KRSBUSD"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=KRSBUSD')}
          >
            KRS/BUSD
          </Menu.Item>
          <Menu.Item
            key="contracts_to_deactivate:SQUADCAKE"
            onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=SQUADCAKE')}
          >
            SQUAD/CAKE
          </Menu.Item>
          <Menu.Item key="contracts_to_deactivate:INKY" onClick={openMenuPage('/products-contracts?status=PENDING_DEACTIVATION&symbol=INKY')}>
            INKY
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="pending_withdrawals" onClick={openMenuPage('/wallets-transactions?type=withdrawal&status=PENDING_CONFIRMATION')}>
          Pending Withdrawals
        </Menu.Item>

        <Menu.Item
          key="pending_harvest"
          onClick={openMenuPage('/wallets-transactions?type=product_contract_harvest&status=PENDING_CONFIRMATION')}
        >
          Pending Harvest
        </Menu.Item>

        {/* <Menu.Item key="invoices" onClick={openMenuPage('/invoices')}>
          Invoices
        </Menu.Item> */}
      </Menu>
      <div>
        <Button type="link" shape="circle" style={{ color: '#001529' }} onClick={signOut}>
          <Icon type="logout" />
        </Button>
      </div>
    </Layout.Header>
  )
}

export default withRouter(Header)
