import * as React from 'react'
import moment from './moment'
import { padStart } from 'lodash'
import { DefaultMomentProps } from './types'
import { useTimeIntervalRefresh } from './time-interval-refresh-hook'

export interface CounterFromNowProps extends DefaultMomentProps {
  asWeeks?: boolean
  hideHours?: boolean
  withSeconds?: boolean
  simpleTheme?: boolean
  inverseSignal?: boolean
  refreshInterval?: number
}
export const CounterFromNow: React.FC<CounterFromNowProps> = ({
  time,
  asWeeks,
  hideHours,
  withSeconds,
  simpleTheme,
  inverseSignal,
  refreshInterval,
}) => {
  const refreshTicker = useTimeIntervalRefresh(refreshInterval)

  const counter = React.useMemo(() => {
    if (!time) return null
    if (!refreshTicker) return null
    const duration = moment.duration(inverseSignal ? moment(time).diff(moment()) : moment().diff(time))
    const counterParts: string[] = []

    if (asWeeks) {
      if (duration.weeks()) counterParts.push(duration.weeks() + 's')
    } else {
      if (duration.years()) counterParts.push(duration.years() + 'a')
      if (duration.months()) counterParts.push(duration.months() + 'm')
      if (duration.days()) counterParts.push(duration.days() + 'd')
    }

    if (!hideHours) {
      let hours: string = ''
      if (counterParts.length > 0 || duration.hours()) hours += padStart(duration.hours().toString(), 2, '0') + (simpleTheme ? ':' : 'h')
      hours += padStart(duration.minutes().toString(), 2, '0') + (simpleTheme ? (withSeconds ? ':' : '') : 'm')
      if (withSeconds) hours += padStart(duration.seconds().toString(), 2, '0') + (simpleTheme ? '' : 's')
      counterParts.push(hours)
    }

    return counterParts.join(' ')
  }, [time, asWeeks, hideHours, withSeconds, refreshTicker, simpleTheme, inverseSignal])

  return <>{counter}</>
}
