import { first } from 'lodash'
import { resolveLanguage } from './locales'

const LANG_KEY_PREFIX = 'lang/'

function getUserLanguageStorageKey(): string {
  return LANG_KEY_PREFIX + 'user'
}

export function setLanguage(language: string) {
  const userLangKey = getUserLanguageStorageKey()
  localStorage.setItem(userLangKey, language)
  window.location.reload()
}

export function getLanguage(): string {
  const userLangKey = getUserLanguageStorageKey()
  const userLang = localStorage.getItem(userLangKey)
  if (userLang) return resolveLanguage(userLang)
  return resolveLanguage(navigator.language)
}

export function getDialect(): string {
  return first(getLanguage().split('-')) || 'en'
}

export function resetLanguage(): void {
  const userLangKey = getUserLanguageStorageKey()
  if (userLangKey) localStorage.removeItem(userLangKey)
}
