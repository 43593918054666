import { WalletTransaction } from '@allcoinwallet/inkryptus-db-schema'
import { Decimal, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { Col, Row, Card, Table, DatePicker, Radio } from 'antd'
import { RangePickerValue } from 'antd/lib/date-picker/interface'
import { RadioChangeEvent } from 'antd/lib/radio'
import { filter, map, sortBy, reverse } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import DecimalFormatter from '../../../components/decimal-formatter'
import { useDatabaseObject } from '../../../hooks/database'
import { TableColumnProps } from '../../../hooks/table'
import { db } from '../../../services/firebase'
import { formatDate, formatHours } from '../../../utils/format'
import { getBscTransactionExplorerUrl } from '../../../utils/testnet'

type TableItem = WalletTransaction & { transactionId: string }

// const selectButtonsOptions = [
//   { value: '', label: 'All' },
//   { value: 'CAKE', label: 'Cake' },
//   { value: 'BANANA', label: 'Banana' },
//   { value: 'BSW', label: 'Biswap' },
// ]

const columns: TableColumnProps<TableItem>[] = [
  {
    key: 'symbol',
    dataIndex: 'symbol',
    title: '#',
  },
  {
    key: 'id',
    title: 'ID',
    render: (_, row) => {
      const txid = row.type === 'deposit' ? row.transactionId : row.confirmationTransactionHash
      if (txid)
        return (
          <a href={getBscTransactionExplorerUrl(txid)} target="_blank" referrerPolicy="no-referrer">
            {txid}
          </a>
        )
      return row.transactionId
    },
  },
  {
    key: 'timestamp',
    title: 'Time',
    // sortable: true,
    render: (_, row) => <p>{`${formatDate(row.timestamp)} | ${formatHours(row.timestamp)}`}</p>,
  },
  {
    key: 'type',
    title: 'Type',
    render: (_, row) => <p>{row.type}</p>,
  },
  {
    key: 'status',
    title: 'Status',
    render: (_, row) => (
      <p className={row.confirmationTime ? 'success' : !row.failureReason ? 'warning' : ''}>
        {row.confirmationTime ? 'CONFIRMED' : row.failureReason ? 'FAILED' : 'PROCESSING'}
      </p>
    ),
  },
  {
    key: 'gross',
    title: 'Gross',
    // sortable: true,
    render: (_, row) => (
      <p>
        <DecimalFormatter value={row.amount} />
      </p>
    ),
  },
  {
    key: 'fee',
    title: 'Fee',
    // sortable: true,
    render: (_, row) => (
      <p>
        <DecimalFormatter value={row.type !== 'deposit' ? row.feeAmount : Decimal(0)} />
      </p>
    ),
  },
  {
    key: 'net',
    title: 'Net',
    // sortable: true,
    render: (_, row) => (
      <span className={row.type === 'withdrawal' || row.type === 'product_contract_fund' ? 'danger' : 'success'}>
        <DecimalFormatter value={row.type !== 'deposit' ? sub(row.amount, row.feeAmount) : row.amount} />
      </span>
    ),
  },
]

export interface MemberWalletHistoricCardProps {
  uid?: string
}

export const MemberWalletHistoricCard: React.FC<MemberWalletHistoricCardProps> = ({ uid }) => {
  const [filterType, setFilterType] = React.useState<'last_100' | 'date_range'>('date_range')
  const changeFilterType = React.useCallback(
    (event: RadioChangeEvent) => {
      setFilterType(event.target.value === 'last_100' ? 'last_100' : 'date_range')
    },
    [setFilterType]
  )

  const [dateStart, setDateStart] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [dateEnd, setDateEnd] = React.useState(moment().format('YYYY-MM-DD'))
  const dateRangeFormats = React.useMemo(() => ['DD/MM/YYYY', 'YYYY-MM-DD'], [])
  const dateRangeValues = React.useMemo(
    (): [moment.Moment, moment.Moment] => [moment(dateStart, 'YYYY-MM-DD'), moment(dateEnd, 'YYYY-MM-DD')],
    [dateStart, dateEnd]
  )
  const changeDateRange = React.useCallback(
    (value: RangePickerValue) => {
      if (typeof value !== 'object') return
      if (!value[0]) return
      if (!value[1]) return
      setDateStart(value[0].format('YYYY-MM-DD'))
      setDateEnd(value[1].format('YYYY-MM-DD'))
    },
    [setDateStart, setDateEnd]
  )

  const activeSortColumnKey: string = 'timestamp'
  const activeSortDirection: 'asc' | 'desc' = activeSortColumnKey ? 'desc' : 'asc'
  // const { activeSortColumnKey, activeSortDirection /* , toggleColumnSort */ } = useSmartTableSort({
  //   columnKey: 'timestamp',
  //   direction: 'desc',
  // })
  const [symbolFilter /* , setSymbolFilter */] = React.useState<string>('')

  let transactionsQuery = uid
    ? db
        .child('walletsTransactions')
        .orderByChild('_indexes/userId__timestamp')
        .startAt(`${uid}__${dateStart.length > 0 && filterType === 'date_range' ? String(moment(dateStart).valueOf()) : '0000000000000'}`)
        .endAt(
          `${uid}__${dateEnd.length > 0 && filterType === 'date_range' ? String(moment(dateEnd).add(1, 'day').valueOf()) : '9999999999999'}`
        )
    : undefined
  if (filterType === 'last_100' && transactionsQuery) transactionsQuery = transactionsQuery.limitToLast(100)
  const transactions = useDatabaseObject(transactionsQuery)

  const transactionsList = React.useMemo(() => {
    if (!transactions.value) return []
    let list = map(transactions.value, ({ _indexes, ...t }, transactionId) => ({ ...t, transactionId }))
    if (symbolFilter) {
      list = filter(list, (row) => row.symbol === symbolFilter)
    }
    if (activeSortColumnKey === 'gross') {
      list = sortBy(list, (row) => Number(row.amount))
    } else if (activeSortColumnKey === 'fee') {
      list = sortBy(list, (row) => (row.type !== 'deposit' ? Number(row.feeAmount) : 0))
    } else if (activeSortColumnKey === 'net') {
      list = sortBy(list, (row) => Number(row.type !== 'deposit' ? sub(row.amount, row.feeAmount) : row.amount))
    } else {
      list = sortBy(list, 'timestamp')
    }
    return activeSortDirection === 'asc' ? list : reverse(list)
  }, [transactions.value, activeSortColumnKey, activeSortDirection, symbolFilter])

  // return (
  //   <SmartTable
  //     columns={columns}
  //     rowKey="transactionId"
  //     rows={transactionsList}
  //     sort={{
  //       activeSortDirection,
  //       activeSortColumnKey,
  //       onSort: toggleColumnSort,
  //     }}
  //     filter={{
  //       selectButtonsOptions,
  //       setSelectedButtonOption: setSymbolFilter,
  //       selectedButtonOption: symbolFilter,
  //       dateStart,
  //       dateEnd,
  //       setDateStart,
  //       setDateEnd,
  //     }}
  //   />
  // )

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title={
            <Radio.Group value={filterType} onChange={changeFilterType}>
              <Radio.Button value="date_range">Date Range</Radio.Button>
              <Radio.Button value="last_100">Last 100</Radio.Button>
            </Radio.Group>
          }
          extra={
            filterType === 'date_range' ? (
              <DatePicker.RangePicker value={dateRangeValues} onChange={changeDateRange} format={dateRangeFormats} />
            ) : undefined
          }
        >
          <Table rowKey="transactionId" columns={columns} dataSource={transactionsList} />
        </Card>
      </Col>
    </Row>
  )
}
