import * as React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { Modal, List } from 'antd'
import { keys, size } from 'lodash'
import { LockTransactionListItem } from './LockTransactionListItem'

export interface LockedTransactionsListModalProps {
  state?: {
    type: 'stake' | 'swap'
    symbol: string
    lockTransactions: { [transactionId: string]: decimal }
  }
  onClose?: () => void
}

export const LockedTransactionsListModal: React.FC<LockedTransactionsListModalProps> = ({ state, onClose }) => {
  const lockTransactions = state?.lockTransactions
  const transactionsCount = React.useMemo(() => size(lockTransactions), [lockTransactions])
  const list = React.useMemo(() => keys(lockTransactions), [lockTransactions])
  return (
    <Modal
      title={`${transactionsCount} Transaction${transactionsCount !== 1 ? 's' : ''} locked`}
      visible={!!state}
      onCancel={onClose}
      footer={null}
      className="locked-transactions-list-modal"
    >
      {!!state && (
        <List
          className="lock-transactions-list"
          loading={false}
          itemLayout="horizontal"
          // loadMore={loadMore}
          dataSource={list}
          renderItem={(transactionId: string) => (
            <LockTransactionListItem key={transactionId} transactionId={transactionId} type={state?.type} />
          )}
        />
      )}
    </Modal>
  )
}
