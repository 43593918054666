import React from 'react'
import SwapWalletsTransactionsManagementTable from './SwapWalletsTransactionsManagementTable.container'

const SwapWalletsTransactionsPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-wallets-transactions">
      <div>
        <SwapWalletsTransactionsManagementTable />
      </div>
    </div>
  )
}

export default SwapWalletsTransactionsPage
