import React from 'react'
import { map, trim } from 'lodash'
import MembersTable, { TableItem } from './MembersTable.component'
import { Members } from '../../modules'
import { TableRowSelection } from 'antd/lib/table'
import { MembersNotificationSenderModal } from './MembersNotificationSenderModal'

const MembersManagementTable: React.FunctionComponent = () => {
  const [members, loadingMembers] = Members.useMembersProfile()
  const [networkMembers, loadingNetworkMembers] = Members.useNetworkMembers()
  const [membersStatuses, loadingMembersStatuses] = Members.useMembersStatuses()
  const membersDataSource: TableItem[] = React.useMemo(() => {
    if (!members || !membersStatuses || !networkMembers) return []
    return map(
      members,
      (m, username): TableItem => ({
        uid: m.userId,
        username,
        name: m.name,
        lastName: m.lastName,
        fullName: `${trim(m.name)} ${trim(m.lastName)}`,
        email: m.email,
        country: m.country,
        lang: m.lang || '',
        creationTime: membersStatuses[m.userId] ? membersStatuses[m.userId].creationTime : undefined,
        sponsorUsername: networkMembers[username].sponsor,
      })
    )
  }, [members, membersStatuses, networkMembers])

  const [selectedMembers, setSelectedMembers] = React.useState<TableItem[]>([])
  const rowSelection: TableRowSelection<TableItem> & { selectedRowKeys: string[] } = React.useMemo(() => {
    return {
      selectedRowKeys: map(selectedMembers, 'username'),
      onChange: (_, rows) => setSelectedMembers(rows || []),
      // getCheckboxProps: (member) => ({
      //   disabled: member.,
      // }),
    }
  }, [selectedMembers, setSelectedMembers])

  const toggleAllMembersSelection = React.useCallback(() => {
    if (membersDataSource.length === selectedMembers.length) {
      setSelectedMembers([])
    } else {
      setSelectedMembers(membersDataSource)
    }
  }, [membersDataSource, selectedMembers, setSelectedMembers])

  const [showNotificationModal, setShowNotificationModal] = React.useState(false)
  const [singleNotificationUsernames, setSingleNotificationUsernames] = React.useState<string[]>([])
  const setSingleNotificationUsername = React.useCallback(
    (username: string) => {
      setSingleNotificationUsernames([username])
    },
    [setSingleNotificationUsernames]
  )
  const clearSingleNotificationUsernames = React.useCallback(() => {
    setSingleNotificationUsernames([])
  }, [setSingleNotificationUsernames])

  const openNotificationModal = React.useCallback(() => {
    setShowNotificationModal(true)
  }, [setShowNotificationModal])
  const closeNotificationModal = React.useCallback(() => {
    setShowNotificationModal(false)
  }, [setShowNotificationModal])

  const loading = loadingMembers || loadingNetworkMembers || loadingMembersStatuses
  return (
    <>
      <MembersTable
        members={membersDataSource}
        loading={loading}
        rowSelection={rowSelection}
        onNotifyMembersClick={openNotificationModal}
        onNotifyMemberClick={setSingleNotificationUsername}
        onSelectAllClick={toggleAllMembersSelection}
      />
      <MembersNotificationSenderModal show={showNotificationModal} onClose={closeNotificationModal} usernames={rowSelection.selectedRowKeys} />
      <MembersNotificationSenderModal
        show={singleNotificationUsernames.length > 0}
        onClose={clearSingleNotificationUsernames}
        usernames={singleNotificationUsernames}
      />
    </>
  )
}

export default MembersManagementTable
