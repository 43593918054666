import React from 'react'
import LicensesManagementTable from './LicensesManagementTable.container'

const LicensesPage: React.FunctionComponent = () => {
  return (
    <div className="full-width box-licenses">
      <div>
        <LicensesManagementTable />
      </div>
    </div>
  )
}

export default LicensesPage
