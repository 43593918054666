import { add, Decimal, sig, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { MemberWallet } from '@allcoinwallet/inkryptus-db-schema'
import * as React from 'react'
import { Row, Col, Card, Statistic, Icon } from 'antd'
import { useMemberSwapWallet, useMemberWallet } from '../../../modules/wallets'
import { formatDecimal } from '../../../utils/format'

export interface MemberWalletBalanceCardsProps {
  uid?: string
  symbol: string
  swap?: boolean
}

export const MemberWalletBalanceCards: React.FC<MemberWalletBalanceCardsProps> = ({ uid, symbol, swap }) => {
  const [memberStakeWallet] = useMemberWallet(swap ? undefined : uid, symbol)
  const [memberSwapWallet] = useMemberSwapWallet(swap ? uid : undefined, symbol)
  const memberWallet = memberStakeWallet || memberSwapWallet

  const availableBalance = memberWallet?.balance.available ? trunc(memberWallet?.balance.available, 8) : undefined
  const totalBalance = memberWallet?.balance.total ? trunc(memberWallet?.balance.total, 8) : undefined
  const contractLockedBalance = memberWallet?.balance.total ? (memberWallet as MemberWallet).balance.contractLocked || {} : undefined
  const pendingTransactionBalance = memberWallet?.balance.total ? memberWallet?.balance.pendingTransaction || {} : undefined
  const lockedBalance = React.useMemo(() => {
    if (!contractLockedBalance && !pendingTransactionBalance) return
    let totalLockedBalance = Decimal(0)
    for (const lockId in contractLockedBalance) totalLockedBalance = add(totalLockedBalance, contractLockedBalance[lockId])
    for (const lockId in pendingTransactionBalance) totalLockedBalance = add(totalLockedBalance, pendingTransactionBalance[lockId])
    return trunc(totalLockedBalance, 8)
  }, [contractLockedBalance, pendingTransactionBalance])
  return (
    <Row gutter={16} style={{ marginBottom: 16 }}>
      <Col span={8}>
        <Card>
          <Statistic
            title={`${symbol} Available`}
            value={availableBalance ? formatDecimal(availableBalance, 8) : '-'}
            valueStyle={availableBalance && sig(availableBalance) > 0 ? { color: '#3f8600' } : { opacity: 0.5 }}
            prefix={<Icon type="unlock" />}
            suffix={symbol}
            decimalSeparator="."
            groupSeparator=","
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic
            title={`${symbol} Locked`}
            value={lockedBalance ? formatDecimal(lockedBalance, 8) : '-'}
            valueStyle={lockedBalance && sig(lockedBalance) > 0 ? { color: '#cf1322' } : { opacity: 0.5 }}
            prefix={<Icon type="lock" />}
            suffix={symbol}
            decimalSeparator="."
            groupSeparator=","
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic
            title={`${symbol} Total`}
            value={totalBalance ? formatDecimal(totalBalance, 8) : '-'}
            valueStyle={totalBalance && sig(totalBalance) ? { color: '#1890ff' } : { opacity: 0.5 }}
            prefix={<Icon type="calculator" />}
            suffix={symbol}
            decimalSeparator="."
            groupSeparator=","
          />
        </Card>
      </Col>
    </Row>
  )
}
