import React from 'react'
import { Table } from 'antd'
import { ColumnProps, TableRowSelection } from 'antd/lib/table'
import { add, Decimal, decimal, trunc } from '@allcoinwallet/invest-bitcoin-decimal'

import { ProductContractRow } from './all-products-contracts.hook'
import { BscLinkIcon } from '../../components/bsc-link-icon'
import { CounterFromNow, DateAndHour, HumanizeFromNow } from '../../components/moment'
import { formatPercent } from '../../utils/format'

const MAX_CAKE_DECIMALS = 8

const columns: ColumnProps<ProductContractRow>[] = [
  {
    title: 'ID',
    dataIndex: 'contractId',
    key: 'contractId',
    render: (contractId: string) => (
      <p className="text-overflow-ellipsis" style={{ maxWidth: 120, display: 'inline-block' }}>
        <BscLinkIcon txId={contractId} /> {contractId}
      </p>
    ),
  },
  {
    title: 'Product',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Member',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'profile.email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, contract) => (contract.lastProfit === undefined && contract.status === 'ACTIVE' ? `PENDING_EMPTY_CYCLE` : status),
  },
  {
    title: 'Payment Time',
    dataIndex: 'paymentStartTime',
    key: 'paymentStartTime',
    defaultSortOrder: 'descend',
    sorter: (recordA, recordB) => recordA.paymentStartTime - recordB.paymentStartTime,
    render: (paymentStartTime: number) => (
      <p>
        <DateAndHour time={paymentStartTime} />
      </p>
    ),
  },
  {
    title: 'Activation Time',
    dataIndex: 'activationTime',
    key: 'activationTime',
    sorter: (recordA, recordB) => (recordA.activationTime || 0) - (recordB.activationTime || 0),
    render: (paymentStartTime: number) =>
      !paymentStartTime ? null : (
        <p>
          <DateAndHour time={paymentStartTime} /> (<HumanizeFromNow time={paymentStartTime} />)
        </p>
      ),
  },
  {
    title: 'Finish Request Time',
    dataIndex: 'requestToFinishTime',
    key: 'requestToFinishTime',
    sorter: (recordA, recordB) => (recordA.requestToFinishTime || 0) - (recordB.requestToFinishTime || 0),
    render: (requestToFinishTime?: number) =>
      !requestToFinishTime ? null : (
        <p>
          <DateAndHour time={requestToFinishTime} /> (<CounterFromNow time={requestToFinishTime} />)
        </p>
      ),
  },
  {
    title: 'Last Profit Time',
    dataIndex: 'lastProfitTime',
    key: 'lastProfitTime',
    sorter: (recordA, recordB) => (recordA.lastProfitTime || 0) - (recordB.lastProfitTime || 0),
    render: (lastProfitTime: number, record) =>
      !lastProfitTime ? null : (
        <p>
          <DateAndHour time={lastProfitTime} /> (no. {record.profitReportsCounter || 0})
        </p>
      ),
  },
  {
    title: 'Initial Net Amount',
    dataIndex: 'startNetAmount',
    key: 'startNetAmount',
    sorter: (recordA, recordB) => Number(recordA.startNetAmount) - Number(recordB.startNetAmount),
    render: (startNetAmount: decimal) => trunc(startNetAmount, MAX_CAKE_DECIMALS),
  },
  {
    title: 'Net Amount',
    dataIndex: 'netAmount',
    key: 'netAmount',
    sorter: (recordA, recordB) => Number(recordA.netAmount) - Number(recordB.netAmount),
    render: (netAmount: decimal) => trunc(netAmount, MAX_CAKE_DECIMALS),
  },
  {
    title: 'Total Profit',
    dataIndex: 'totalProfit',
    key: 'totalProfit',
    sorter: (recordA, recordB) => (recordA.lastProfit || 0) - (recordB.lastProfit || 0),
    render: (_, contract) => (
      <p>
        {trunc(add(Decimal(contract.totalProfitNetAmount || 0), Decimal(contract.totalProfitFeeAmount || 0)), MAX_CAKE_DECIMALS)} (
        {formatPercent(contract.totalProfit || 0)})
      </p>
    ),
  },
]

interface Props {
  contracts: Array<ProductContractRow>
  rowSelection?: TableRowSelection<ProductContractRow>
}
const ProductsContractsTable: React.FunctionComponent<Props> = (props) => {
  return (
    <Table
      dataSource={props.contracts}
      size="small"
      columns={columns}
      pagination={false}
      rowKey="contractId"
      rowSelection={props.rowSelection}
    />
  )
}

export default ProductsContractsTable
