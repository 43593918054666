import React from 'react'
import { Icon } from 'antd'
import { isProd } from '../../config'

export interface BscLinkIconProps {
  txId?: string
  address?: string
}

export const BscLinkIcon: React.FC<BscLinkIconProps> = ({ txId, address }) => {
  const url = React.useMemo(() => {
    if (isProd) {
      if (txId) return `https://bscscan.com/tx/${txId}`
      if (address) return `https://bscscan.com/address/${address}`
    }
    if (txId) return `https://testnet.bscscan.com/tx/${txId}`
    if (address) return `https://testnet.bscscan.com/address/${address}`
    return ''
  }, [txId, address])

  const followUrl = React.useCallback(() => {
    if (!url) return
    window.open(url, '_blank')
  }, [url])

  if (!url) return null
  return <Icon type="link" onClick={followUrl} />
}
