const MAX_ASCII_VALID_DEC = 126
const DIACRITICS = 'ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźžĄÀÁÄÂÃÅÆĂĆČĈĘÈÉËÊĜĤÌÍÏÎĴŁĽŃŇÒÓÖŐÔÕÐØŚȘŞŠŜŤȚŢŬÙÚÜŰÛÑŸÝÇŻŹŽ'
const DISCRITICS_ASCII_EVAL =
  'aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzzAAAAAAAAACCCEEEEEGHIIIIJLLNNOOOOOOOOSSSSSTTTUUUUUUNYYCZZZ'

/**
 * Replace DIACRITIC char
 */
export function replaceDiacriticChar(char: string) {
  const i = DIACRITICS.indexOf(char)
  return i === -1 ? char : DISCRITICS_ASCII_EVAL[i]
}

/**
 * Delete ASCII invalid chars of an string
 */
export function deleteInvalidASCIIChar(char: string) {
  return char.charCodeAt(0) <= MAX_ASCII_VALID_DEC ? char : ''
}

/**
 * Verify if an string is a valid ASCII string (chars <= 126 decimal)
 */
export function isASCII(str: string) {
  const length = str.length
  for (let i = 0; i < length; ++i) {
    if (str.charCodeAt(i) > MAX_ASCII_VALID_DEC) return false
  }
  return true
}

/**
 * Transform a string to an ASCII valid and cleaned string
 */
export function toCleanedASCII(str: string) {
  return str.replace(/.{1}/g, char => deleteInvalidASCIIChar(replaceDiacriticChar(char)))
}
