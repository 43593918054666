import { useState } from 'react'
import { FormErrors } from '../utils/form-errors'

export function useFormErrors(): [FormErrors, (e: FormErrors) => void, (fieldName: string) => void] {
  const [errors, setErrors] = useState<FormErrors>({})
  const solveError = (fieldName: string) => {
    const e = { ...errors }
    delete e[fieldName]
    setErrors(e)
  }
  return [errors, setErrors, solveError]
}
