import * as React from 'react'
import { Modal, Form, Input, Button, notification } from 'antd'
import { sig, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { parseDecimal } from '../../utils/format'
import { swapWalletsWithdrawConversionVault } from '../../services/api'
import { getBscTransactionExplorerUrl } from '../../utils/testnet'

export interface SwapConversionVaultWithdrawalModalProps {
  symbol?: string
  onClose?: () => void
}

export const SwapConversionVaultWithdrawalModal: React.FC<SwapConversionVaultWithdrawalModalProps> = ({ symbol, onClose }) => {
  const [grossAmountText, setGrossAmountText] = React.useState('')
  const changeGrossAmountText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGrossAmountText(event.target.value)
    },
    [setGrossAmountText]
  )
  const formatGrossAmountText = React.useCallback(() => {
    setGrossAmountText((v) => parseDecimal(v) || '')
  }, [setGrossAmountText])

  const formData = React.useMemo(() => {
    let grossAmount = parseDecimal(grossAmountText)
    if (grossAmount) grossAmount = trunc(grossAmount, 8)
    if (grossAmount && sig(grossAmount) > 0) return { grossAmount }
    return
  }, [grossAmountText])

  const [withdrawing, setWithdrawing] = React.useState(false)
  const withdraw = React.useCallback(async () => {
    if (!symbol) return
    if (!formData) return
    if (withdrawing) return
    setWithdrawing(true)
    try {
      const { transactionId } = await swapWalletsWithdrawConversionVault(symbol, formData.grossAmount)
      const explorerUrl = getBscTransactionExplorerUrl(transactionId)
      notification.success({
        message: `Withdrawal broadcasted successfully!`,
        description: (
          <Button type="link" href={explorerUrl} target="_blank">
            {transactionId}
          </Button>
        ),
        duration: 0,
      })
      if (onClose) onClose()
    } catch (error) {
      notification.error({ message: `Failed to process withdraw! ${(error as any).message}` })
    }
    setWithdrawing(false)
  }, [symbol, withdrawing, setWithdrawing, formData, onClose])

  React.useEffect(() => {
    setGrossAmountText('')
  }, [symbol, setGrossAmountText])
  return (
    <Modal
      title={`${symbol} Swap Conversion Vault Withdrawal`}
      visible={!!symbol}
      onCancel={onClose}
      footer={null}
      className="swap-conversion-vault-withdrawal-modal"
    >
      <Form layout="vertical">
        <Form.Item label="Gross Amount">
          <Input placeholder="0.00000000" value={grossAmountText} onChange={changeGrossAmountText} onBlur={formatGrossAmountText} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" disabled={!formData} loading={withdrawing} onClick={withdraw}>
            Withdraw
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
