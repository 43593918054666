import * as React from 'react'
import moment from './moment'
import { DefaultMomentProps } from './types'
import { useTimeIntervalRefresh } from './time-interval-refresh-hook'

export interface HumanizeFromNowProps extends DefaultMomentProps {
  suffix?: boolean
}
export const HumanizeFromNow: React.FC<HumanizeFromNowProps> = ({ time, suffix }) => {
  const refreshTicker = useTimeIntervalRefresh()

  const humanizedCounter = React.useMemo(() => {
    if (!time) return null
    if (!refreshTicker) return null
    return moment(time).fromNow(!suffix)
  }, [time, suffix, refreshTicker])

  return <>{humanizedCounter}</>
}
