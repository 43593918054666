import React from 'react'
import LicensesTable from './LicensesTable.component'
import useAllLicenses from './all-licenses.hook'

const LicensesManagementTable: React.FunctionComponent = () => {
  const [licenses] = useAllLicenses()
  return <LicensesTable licenses={licenses} />
}

export default LicensesManagementTable
