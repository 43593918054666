import { Modal, Row, Col, Button, Form, Input, Divider } from 'antd'
import React from 'react'
import { trim } from 'lodash'
import { useMemberProfile, useMemberStatuses, usePublicProfile } from '../../../modules/members'
import CloudImage, { Transformation } from '../../../components/cloud-image'
import { MemberBlockSwitcher } from './MemberBlockSwitcher'
import { MemberInfoField } from './MemberInfoField'
import { MemberWalletBalanceCards } from './MemberWalletBalanceCards'
import { MemberWalletHistoricCard } from './MemberWalletHistoricCard'

export interface MemberOverviewModalProps {
  username?: string
  onClose: () => any
  onNotifyMemberClick?: (username: string) => void
}

export const MemberOverviewModal: React.FC<MemberOverviewModalProps> = ({ username, onClose, onNotifyMemberClick }) => {
  const [profile, profileLoading] = useMemberProfile(username)
  const fullName = profile ? `${trim(profile.name)} ${profile.lastName}` : undefined

  const [statuses, statusesLoading] = useMemberStatuses(profile?.userId)

  const [publicProfile, publicProfileLoading] = usePublicProfile(username)
  const profilePictureVersion = publicProfile ? publicProfile.pictureVersion : undefined

  const propagateNotifyMemberClick = React.useCallback(() => {
    if (onNotifyMemberClick && username) onNotifyMemberClick(username)
  }, [username, onNotifyMemberClick])

  const loading = profileLoading || publicProfileLoading || statusesLoading || (!!profile?.userId && !statuses)

  return (
    <Modal
      width="90vw"
      title={fullName || 'Member Profile'}
      visible={!!username}
      onCancel={onClose}
      footer={null}
      className="member-overview-modal"
    >
      <Row>
        <Col span={4} className="personal-info-sidebar">
          <div className="avatar">
            {loading ? (
              <CloudImage spinner>
                <Transformation crop="fill" width="480" height="480" />
              </CloudImage>
            ) : (
              <CloudImage name={username ? `usernames_pictures/${username}` : undefined} fallbackAvatar version={profilePictureVersion}>
                <Transformation crop="thumb" gravity="face" width="480" height="480" />
              </CloudImage>
            )}
          </div>
          <div>
            <MemberBlockSwitcher loading={loading} uid={profile?.userId} blocked={!!statuses?.blockTime} />
          </div>
          <div>
            <Button icon="bell" onClick={propagateNotifyMemberClick}>
              Send Notification
            </Button>
          </div>
          <div>
            <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
          </div>
        </Col>
        <Col span={12} className="personal-info-fields">
          <Form layout="vertical">
            <Form.Item label="Name">
              <MemberInfoField username={username} fieldName="name" fieldValue={profile?.name || ''} editable />
            </Form.Item>
            <Form.Item label="Last Name">
              <MemberInfoField username={username} fieldName="lastName" fieldValue={profile?.lastName || ''} editable />
            </Form.Item>
            <Form.Item label="Email">
              <MemberInfoField username={username} fieldName="email" fieldValue={profile?.email || ''} editable />
            </Form.Item>
            <Form.Item label="Phone">
              <MemberInfoField username={username} fieldName="phone" fieldValue={profile?.phone || ''} editable />
            </Form.Item>
          </Form>
        </Col>
        <Col span={8} className="personal-info-fields">
          <Form layout="vertical">
            <Form.Item label="Username">
              <Input value={username} style={{ width: 'calc(100% - 32px)' }} />
            </Form.Item>
            <Form.Item label="Country(TODO: Select)">
              <MemberInfoField username={username} fieldName="country" fieldValue={profile?.country || ''} editable />
            </Form.Item>
            <Form.Item label="Gender(TODO: Select)">
              <MemberInfoField username={username} fieldName="gender" fieldValue={profile?.gender || ''} editable />
            </Form.Item>
            <Form.Item label="Birth Date">
              <MemberInfoField username={username} fieldName="birthDate" fieldValue={profile?.birthDate || ''} editable />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider orientation="left">Wallets</Divider>
      <div className="stake-section">
        <MemberWalletBalanceCards uid={profile?.userId} symbol="CAKE" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BSW" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BANANA" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="USDT" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BUSD" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="SHIB" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="OLEBUSD" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="GRVSBUSD" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="VTGBNB" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="WMXBUSD" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="KRSBUSD" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="SQUADCAKE" />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="INKY" />
        <MemberWalletHistoricCard uid={profile?.userId} />
      </div>

      {/* <Divider orientation="left">Swap</Divider>
      <div className="swap-section">
        <MemberWalletBalanceCards uid={profile?.userId} symbol="CAKE" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BSW" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BANANA" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="USDT" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="BUSD" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="SHIB" swap />
        <MemberWalletBalanceCards uid={profile?.userId} symbol="MICHI" swap />
        <MemberSwapWalletHistoricCard uid={profile?.userId} />
      </div> */}
    </Modal>
  )
}
