import React from 'react'
import { Divider } from 'antd'
import ContractBalanceWidgets from './ContractBalanceWidgets'
import MembersBalanceWidgets, { MembersBalanceWidgetsProps } from './MembersBalanceWidgets'
import { SwapConversionVaultWithdrawalModal } from './SwapConversionVaultWithdrawalModal'
import { LockedTransactionsListModal, LockedTransactionsListModalProps } from './LockedTransactionsListModal'
import { AvailableOwnersListModal, AvailableOwnersListModalProps } from './AvailableOwnersListModal'

const HomePage: React.FunctionComponent = () => {
  const [swapWithdrawalSymbol, setSwapWithdrawalSymbol] = React.useState('')
  const selectSwapWithdrawalSymbol = React.useCallback(
    (type: 'swap' | 'stake', symbol: string) => {
      if (type === 'swap') setSwapWithdrawalSymbol(symbol)
    },
    [setSwapWithdrawalSymbol]
  )
  const clearSwapWithdrawalSymbol = React.useCallback(() => {
    setSwapWithdrawalSymbol('')
  }, [setSwapWithdrawalSymbol])

  const [lockTransactionsState, setLockTransactionsState] = React.useState<LockedTransactionsListModalProps['state'] | undefined>()
  const selectLockTransactionsState: MembersBalanceWidgetsProps['onLockTransactionsListClick'] = React.useCallback(
    (type, symbol, lockTransactions) => {
      setLockTransactionsState({ type, symbol, lockTransactions })
    },
    [setLockTransactionsState]
  )
  const clearLockTransactionsState = React.useCallback(() => {
    setLockTransactionsState(undefined)
  }, [setLockTransactionsState])

  const [availableBalanceOwnersState, setAvailableBalanceOwnersState] = React.useState<AvailableOwnersListModalProps['state'] | undefined>()
  const selectAvailableBalanceOwnersState: MembersBalanceWidgetsProps['onAvailableBalanceOwnersListClick'] = React.useCallback(
    (type, symbol, availableOwners) => {
      setAvailableBalanceOwnersState({ type, symbol, availableOwners })
    },
    [setAvailableBalanceOwnersState]
  )
  const clearAvailableBalanceOwnersState = React.useCallback(() => {
    setAvailableBalanceOwnersState(undefined)
  }, [setAvailableBalanceOwnersState])

  /**
   * TODO:
   * - [X] conversion vault withdrawal modal
   * - [ ] lock transactions list modal
   * - [ ] available owners list modal
   */
  return (
    <div style={{ padding: 30 }}>
      <SwapConversionVaultWithdrawalModal symbol={swapWithdrawalSymbol} onClose={clearSwapWithdrawalSymbol} />
      <LockedTransactionsListModal state={lockTransactionsState} onClose={clearLockTransactionsState} />
      <AvailableOwnersListModal state={availableBalanceOwnersState} onClose={clearAvailableBalanceOwnersState} />
      <Divider orientation="left">Contract</Divider>
      <ContractBalanceWidgets type="stake" />
      <Divider orientation="left">Wallets</Divider>
      <MembersBalanceWidgets
        type="swap"
        symbol="CAKE"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="BSW"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="BANANA"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="USDT"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="BUSD"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="SHIB"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="OLEBUSD"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="GRVSBUSD"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="VTGBNB"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="WMXBUSD"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="KRSBUSD"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="SQUADCAKE"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
      <MembersBalanceWidgets
        type="swap"
        symbol="INKY"
        onLockTransactionsListClick={selectLockTransactionsState}
        onAvailableBalanceOwnersListClick={selectAvailableBalanceOwnersState}
        onVaultWithdrawalClick={selectSwapWithdrawalSymbol}
      />
    </div>
  )
}

export default HomePage
